<template>
	<div class="container">
		<div class="column">
			<div style="color:#56b55f;font-size: 20px;">{{floorShowName}}</div>
			<div style="font-size: 14px;"><a @click="toList">{{moduleLinkConfig.more.buttonName}} >></a></div>
		</div>
		<div class="banner">
			<el-carousel height="200px" trigger="click" indicator-position="none">
				<el-carousel-item v-if="item.img" v-for="(item, index) in moduleDataSource" :key="index">
					<a @click="toDetail(item)">
						<el-image :src="item.img" style="height: 100%;"></el-image>
						<div class="txt">
							<div class="name">{{ item.title }}</div>
						</div>
					</a>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="fRight" v-for="(item, index) in moduleDataSource" :key="index">
			<div class="title">
				<a @click="toDetail(item)" class="fontOverHidden">{{item.title}}</a>
			</div>
			<div class="data">{{item.time}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ZhongDian',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => [],
			},
			floorShowName: "",
			moduleTable: "",
			moduleLinkConfig: {},
		},
		methods: {
			toList() {
				this.$emit("navitage", this.moduleLinkConfig.more, this.moduleTable);
			},
			toDetail(item) {
				this.$emit(
					"navitage",
					this.moduleLinkConfig.detail,
					this.moduleTable,
					item
				);
			},
		},
	}
</script>


<style lang="less" scoped>
	.container {
		height: 410px;
		width: 390px;
	}

	.column {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
	}

	.banner {
		width: 100%;
		height: 200px;
	}

	.txt {
		padding: 2px;
		height: 16%;
		background: rgba(0, 0, 0, 0.65);
		position: absolute;
		left: 0;
		bottom: 0;
		padding-left: 15px;
		align-items: center;
		font-family: PingFangSC-Medium;
		font-size: 15px;
		color: #ffffff;
		font-weight: 500;
		width: 100%;
		line-height: 34px;
	}

	.txt .name {
		width: 80%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.title {
		height: 30px;
		width: 280px;
		font-size: 15px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		margin-left: 10px;
	}

	.title a::before {
		position: absolute;
		content: "";
		width: 6px;
		height: 6px;
		background: #cccccc;
		top: 22px;
		left: 0;
	}

	.data {
		width: 80px;
		font-size: 14px;
	}

	.fRight {
		height: 52px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		line-height: 30px;
		border-bottom: solid 1px #f5f5f5;
		justify-content: space-between;
		align-items: center;
		position: relative;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.fRight :hover::before {
		background: #3ca079;
	}

	.fRight .date {
		font-size: 14px;
		color: #999999;
	}

	.fRight :hover {
		color: #3ca079;
		font-weight: 500;
	}
</style>