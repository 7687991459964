<template>
	<div class="tab xyzcTab">
		<div class="xyzcTitle">
			<span class="ch">{{floorShowName}}</span>
			<!-- <span class="en">Public Announcement</span> -->
		</div>
		<div class="content">
			<vue-seamless-scroll :data="moduleDataSource" class="warp"  @click.native="handleClick($event)">
				<ul>
					<li v-for="(item,index) in moduleDataSource" :key="index" class="row">
						<el-tooltip class="item" effect="dark" :content="item.title" placement="top-start">
							<a class="fontOverHidden" :data-item="JSON.stringify(item)" >{{ item.title }}</a>
						</el-tooltip>
						<span class="date">{{ item.date }}</span>
					</li>
				</ul>
			</vue-seamless-scroll>
		</div>
	</div>
</template>

<script>
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		name: 'HuaxianCreditPolicy',
		components: {
			vueSeamlessScroll
		},
		created() {
			console.log(this.moduleDataSource)
		},
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			floorShowName: '',
			moduleTable: '',
			moduleLinkConfig: {}
		},
		data() {
			return {
			}
		},
		methods: {
			handleClick($event){
				console.log($event.target)
				let index = JSON.parse($event.target.dataset.item)
				this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable, index)
			}
		}
	}
</script>

<style scoped>
	.content {
		padding: 0 24px;
		overflow: hidden;
		height: 425px;
	}

	.xyzcTab {
		background: #FFFFFF;
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
		border-radius: 4px;
	}

	.xyzcTab li {
		height: 52px;
		border-bottom: solid 1px #f5f5f5;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 15px;
		position: relative;

	}

	.xyzcTab li::before {
		position: absolute;
		content: "";
		width: 6px;
		height: 6px;
		background: #CCCCCC;
		top: 23px;
		left: 0;
	}

	.xyzcTab li:hover::before {
		background: #3CA079;
	}

	.xyzcTab li .date {
		font-size: 14px;
		white-space: nowrap;
		color: #999999;
	}

	.xyzcTab li:hover a {
		color: #3CA079;
		font-weight: 500;
	}

	.xyzcTitle {
		height: 80px;
		background-image: linear-gradient(270deg, #F8B250 0%, #EE7B28 100%);
		border-radius: 4px;
		line-height: 80px;
		padding-left: 24px;
	}

	.ch {
		font-family: PingFangSC-Semibold;
		font-size: 24px;
		color: #FFFFFF;
		font-weight: bold;
		margin-right: 8px;
	}
</style>
