<template>
	<div>
		<div class="content" v-for="item in list" :key="item.id" @click="openinfo(item)">
			<div class="title">
				{{ item.title }}
			</div>
			<div class="time">
				<i class="el-icon-time"></i>
				{{ item.date }}
			</div>
			<div class="source" v-if="item.source">文章来源：{{ item.source }}</div>
		</div>
		<div class="pagination">
			<el-pagination :page-size="modulePagination.pageSize" @current-change="handleCurrentChange"
				layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HuaxianTitleList',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			modulePagination: {},
			moduleLinkConfig: {},
			moduleTable: '',
			floorId: {
				type: String,
				default: ''
			},
			moduleItem: {
				type: Object
			}
		},
		data() {
			return {
				list: [],
				total: this.modulePagination.total,
			}
		},
		watch: {
			moduleDataSource: {
				handler(newVal) {
					console.log(newVal)
					this.list = newVal
				},
				deep: true
			}
		},
		methods: {
			openinfo(item) {
				this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable, item)
			},
			handleCurrentChange(val) {
				console.log(this.moduleItem)
				//发送页码获取数据更新newsList
				this.$emit('getPageList', val, this.moduleItem, this.floorId)
			},
		},
		created() {
			this.list = this.moduleDataSource
			console.log(this.modulePagination)
		},
	}
</script>

<style scoped>
	.content {
		width: 100%;
		height: 33px;
		display: flex;
		justify-content: space-between;
		border-bottom: solid 1px #eee;
		cursor: pointer;
		margin-bottom: 15px;
	}

	.title {
		width: 800px;
		height: 100%;
		font-size: 16px;
		color: #000000;
		line-height: 28px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.time {
		width: 220;
		margin-left: 80px;
	}

	.source {
		width: 220px;
		text-align: right;
	}

	.time,
	.source {
		font-size: 14px;
		color: #999;
		margin-top: 5px;
	}

	.content:hover .title {
		color: #3CA079;
	}

	.pagination {
		text-align: right;
	}

	.pagination .el-pagination {
		margin-bottom: 0;
	}
</style>