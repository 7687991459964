<template>
	<div class="container">
		<!-- 头部 -->
		<div class="header">
			<div class="column">{{floorShowName}}</div>
			<div class="search">
				<div class="column_title">{{floorShowName}}</div>
				<div>
					<el-input placeholder="请输入内容" v-model="input" clearable></el-input>
				</div>
				<div class="submit" @click="search">
					<img src="https://file.tusjf.com/temp/search_1708657813192.png" alt=""
						style="margin-left: 8px;margin-top: 2px;" />
				</div>
			</div>
		</div>
		<!-- 列表 -->
		<div class="list">
			<div class="box" v-for="item in moduleDataSource" :key="item.id" @click="view(item)">
				<div style="display: flex;align-items: center;">
					<div class="img" v-if="item.img != ''">
						<img :src="item.img" alt="" style="height: 100%; " />
					</div>
					<div style="flex: 1;">
						<div class="name">{{item.name}}</div>
						<div class="name" style="color: orange;">{{item.price}}{{item.unit}}</div>
						<div class="name">品牌：{{item.brand}}</div>
						<div class="name ellipsis">成分：{{item.component}}</div>
					</div>
				</div>
				<!-- <div class="footer_name">
					<div class="address">{{item.address}}</div>
					<div class="time">2024 8.15 17:35</div>
				</div> -->
			</div>
		</div>
		<div class="pagination">
			<el-pagination @current-change="handleCurrentChange" layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SearchList',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => [],
			},
			floorShowName: "",
			moduleTable: "",
			moduleLinkConfig: {},
			modulePagination: {},
			floorId: {
				type: String,
				default: ''
			},
			moduleItem: {
				type: Object
			}
		},
		data() {
			return {
				input: '',
				total: this.modulePagination.total,
			}
		},
		methods: {
			search() {
				this.$emit('getPageList', this.input, this.moduleItem, this.floorId, true)
			},
			handleCurrentChange(val) {
				//发送页码获取数据更新newsList
				console.log(val, this.moduleItem, this.floorId)
				this.$emit('getPageList', val, this.moduleItem, this.floorId)
			},
			view(item) {
				this.$emit(
					"navitage",
					this.moduleLinkConfig.detail,
					this.moduleTable,
					item
				);
			}
		},
		created() {
			console.log(this.moduleDataSource, '11')
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		height: 100%;
		margin: 0 auto;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.column {
		color: #27A26A;
		font-size: 18px;
	}

	.search {
		width: 300px;
		height: 40px;
		border-radius: 6px;
		background-color: #27A26A;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
	}

	/deep/ .el-input__inner {
		height: 36px;
		width: 220px;
	}

	.column_title {
		font-size: 15px;
		color: #fff;
		margin-left: 10px;
	}

	.submit {
		position: absolute;
		right: 0;
		top: 0;
		width: 50px;
		height: 40px;
		border-radius: 5px;
		background-color: #27A26A;
	}

	.list {
		background-color: #F5F5F5;
		padding: 15px 15px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.box {
		padding: 15px;
		width: 350px;
		height: 100%;
		background-color: #fff;
		margin-bottom: 20px;
	}

	.img {
		width: 100px;
		height: 100px;
		margin-right: 20px;
		overflow: hidden;
	}

	.list::after {
		content: "";
		width: 380px;
	}

	.box .name::before {
		content: '';
		display: inline-block;
		width: 15px;
		height: 15px;
		margin-right: 5px;
		background-image: url('https://file.tusjf.com/temp/tag_1708670761040.png');
		background-repeat: no-repeat;
		background-position: -2px 0;
		background-size: 100%;
	}

	.name {
		line-height: 30px;
	}

	.ellipsis {
		width: 215px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.footer_name {
		display: flex;
		font-size: 13px;
		line-height: 30px;
	}

	.address {
		margin-right: 20px;
	}

	.address::before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 17px;
		height: 19px;
		margin-right: 5px;
		/* background-color: aquamarine; */
		background-image: url('https://www.bdnyxx.cn/static/front/demand/img/wz_ico.png');
		background-repeat: no-repeat;
		background-position: 1px 1px;
		background-position: center;
	}

	.time::before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 19px;
		height: 19px;
		margin-right: 5px;
		background-image: url('https://www.bdnyxx.cn/static/front/demand/img/time.png');
		background-repeat: no-repeat;
		background-position: -1px 0;
		background-size: 100%;
	}

	.pagination {
		text-align: right;
	}
</style>