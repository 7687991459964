<template>
	<div class="xyctj">
		<div class="container">
			<div class="top">
				<div>
					<img style="vertical-align: baseline;" src="@/assets/images/title_tips.png" alt="">
					<span class="ch">信用村统计</span>
				</div>
			</div>
			<div class="line">
				<p class="left"></p>
				<p class="right"></p>
			</div>
		</div>
		<div class="echart2">
			<xyc-echarts :data="xycData"></xyc-echarts>
		</div>
	</div>
</template>

<script>
	import xycEcharts from "@/components/xycEcharts"
	import {
		formattingData
	} from '@/utils/util.js'
	export default {
		name: 'HuaxianCircular',
		components: {
			xycEcharts
		},
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			floorShowName: '',
		},
		data() {
			return {
				colorList: ["#3CA079", '#50CCD3', '#4AAAF0', '#EEBC28', '#EE7B28', '#E63E3E'],
				xycData: {
					data: [],
					count: 11
				},
				list: []
			}
		},
		created() {
			this.list = JSON.parse(JSON.stringify(this.moduleDataSource))
			let targetarr = [
				'AAA',
				'AA',
				'A',
				'BBB',
				'BB',
				'B',
				'CCC',
				'CC',
				'C'
			]
			this.list.sort((a, b) => {
				return targetarr.indexOf(a.village_credit_level) - targetarr.indexOf(b.village_credit_level);
			})
			this.list.forEach((item, index) => {
				this.xycData.data.push({
					village_name:item.village_name,
					value: item.amount,
					name: item.village_credit_level+"类信用村",
					itemStyle: {
						color: this.colorList[index]
					}
				})
			})

			/*  {
				            "value": 1,
				            "name": "AAA类信用村",
				            "itemStyle": {
				                "color": "#3CA079"
				            }
				        },
				        {
				            value:2,
				            name:'AA类信用村',
				            itemStyle: {
				                color: '#50CCD3'
				            }
				        },
				        {
				            value:2,
				            name:'A类信用户',
				            itemStyle: {
				                color: '#4AAAF0'
				            }
				        },
				        {
				            value:2,
				            name:'B类信用户',
				            itemStyle: {
				                color: '#EEBC28'
				            }
				        },
				        {
				            value:2,
				            name:'C类信用户',
				            itemStyle: {
				                color: '#EE7B28'
				            }
				        }, */
		}
	}
</script>

<style scoped>
	.echart2 {
		width: 100%;
		height: 380px;
		margin-top: 12px;
	}

	/* 标题 start */
	.container {
		margin: 0 auto;
		height: 54px;
	}

	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}

	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}

	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3CA079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}

	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}

	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3CA079;
		margin-left: 4px;
	}

	.line {
		margin-top: 16px;
	}

	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}

	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}

	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}

	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}

	/* 标题 end */
</style>
