<template>
	<div id="app">
		<div style="position: fixed;width:100%;top:0;background:rgba(255,255,255,1);z-index: 1000;">
			<!-- 头部 logo-->
			<SiteHeader />
			<!-- 导航 -->
			<SiteMenu />
		</div>
		<!-- 动态组件 -->
		<div style="height: 130px;"></div>
		<router-view></router-view>
	</div>
</template>

<script>
	import SiteHeader from '@/components/base/SiteHeader.vue'
	import SiteMenu from '@/components/base/SiteMenu.vue'
	export default {
		name: 'App',
		components:{
			SiteHeader,
			SiteMenu,
		}
	}
</script>

<style>
	#app {
		font-family: PingFangSC-Regular, Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #333;
		font-size: 16px;
		line-height: 1.5;
		position: relative;
		min-height: 100vh;
	}
</style>
