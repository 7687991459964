<template>
	<div class="xytjTab">
		<div class="container">
			<div class="top">
				<div>
					<img style="vertical-align: baseline;" src="@/assets/images/title_tips.png" alt="">
					<span class="ch">{{floorShowName}}</span>
				</div>
				<!-- <div v-if="titleInfo[2]">
	               <router-link :to="{name:titleInfo[3]}">
	                   <span class="more" >更多</span>
	                   <i class="el-icon-arrow-right"></i>
	               </router-link>
	           </div> -->
			</div>
			<div class="line">
				<p class="left"></p>
				<p class="right"></p>
			</div>
		</div>
		<div class="echart1">
			<chart :defOptions="options"></chart>
			<p class="title">信用户类型</p>
		</div>
	</div>
</template>

<script>
	import Chart from "@/components/chart.vue";
	import {
		formattingData
	} from '@/utils/util.js'
	import {
		Series
	} from "highcharts";
	export default {
		name: 'HuaxianColumnar',
		components: {
			Chart
		},
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			floorShowName: '',
		},
		data() {
			return {
				colorList: ["#3CA079", "#50CCD3", "#4AAAF0", "#EEBC28", "#EE7B28", '#E63E3E'],
				options: {
					chart: {
						backgroundColor: '#fff',
						type: 'column',
						//plotBorderColor: '#1c2a38',
						plotBorderWidth: 0,
						fontSize: "16px",
					},
					exporting: {
						enabled: false, //用来设置是否显示‘打印’,'导出'等功能按钮，不设置时默认为显示
					},
					credits: {
						enabled: false // 禁用版权信息
					},
					title: false,
					xAxis: {
						gridLineColor: '#ddd', //网格线
						tickColor: '#ddd', //刻度线
						lineColor: '#ddd', //轴线
						labels: {
							//rotation: -45, // 设置轴标签旋转角度
							style: {
						  fontSize: "14px",
								color: "#666"
							}

						},
						categories: []
					},
					yAxis: {
						min: 0,
						title: false,
						gridLineColor: '#ddd', //网格线
						tickColor: '#1c2a38', //刻度线
						labels: {
							style: {
								color: "#999",
								fontSize: '12px'
							}
						},
						stackLabels: {
							enabled: false,
							style: {
								fontWeight: 'bold',
								color: '#fff',
							} //柱形图上方数据显示
						}
					},
					tooltip: {
						pointFormat: '<span style="color:{series.color};width:10px;height:10px;"><span><span>{series.name}</span>: <b>{point.y}</b><br>',
						//:.0f 表示保留 0 位小数，详见教程：https://www.hcharts.cn/docs/basic-labels-string-formatting 
						// +'({point.percentage:.0f}%)<br/>'
						shared: true
					},
					legend: {
						layout: 'horizontal',
						align: 'right',
					 verticalAlign: 'top',
						itemStyle: {
							cursor: 'pointer',
							color: '#666',
							fontWeight: 'normal',
							fontSize: '14px'
						},
						itemHiddenStyle: {
							color: '#CCC'
						},
					},
					plotOptions: {
						column: { //不显示阴影
					 	stacking: 'normal',
							bar: false,
							borderWidth: 0, //柱子边框的大小
					 },
					},
					series: []
				},
			}
		},
		created() {
			let arr = JSON.parse(JSON.stringify(this.moduleDataSource))
			arr = formattingData(arr, 'xAxis')
			let type = JSON.parse(JSON.stringify(this.moduleDataSource))
			type = formattingData(type, 'credit_level')
			let categories = []
			let series = []
			arr.forEach(item => {
				categories.push(item.xAxis)
			})
			type.forEach((typeItem, typeIndex) => {
				series.push({
					name: typeItem.credit_level
				})
			})
			let targetarr = [
				'AAA',
				'AA',
				'A',
				'BBB',
				'BB',
				'B',
				'CCC',
				'CC',
				'C'
			]
			series.sort((a, b) => {
				return targetarr.indexOf(a.name) - targetarr.indexOf(b.name);
			})
			series.forEach((item,index)=>{
				let data = []
				arr.forEach((arrItem,arrIndex)=>{
					let amount = 0
					arrItem.list.forEach((childItem,childIndex)=>{
						if(item.name == childItem.credit_level){
							amount = childItem.amount
						}
					})
					data.push(amount)
				})
				item.data = data
				item.name = item.name + '类信用户'
				item.color = this.colorList[index]
			})
			this.$set(this.options, 'series', series)
			this.$set(this.options.xAxis, 'categories', categories)
		}
	}
</script>

<style scoped>
	.xytjTab {
		height: 480px;
		background: #fff;
	}

	.echart1 {
		height: 420px;
		position: relative;
		margin-top: 10px;
		min-width: 1200px;
	}

	.echart1 .title {
		position: absolute;
		left: 10px;
		top: 10px;
		font-family: PingFangSC-Semibold;
		font-size: 14px;
		color: #333333;
		font-weight: bold;
		line-height: 24px;
	}

	/* 标题 start */
	.container {
		margin: 0 auto;
		height: 54px;
	}

	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}

	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}

	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3CA079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}

	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}

	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3CA079;
		margin-left: 4px;
	}

	.line {
		margin-top: 16px;
	}

	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}

	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}

	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}

	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}

	/* 标题 end */
</style>
