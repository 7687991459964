<template>
  <div class="BigMinBanner" :style="{backgroundImage:'url('+bigImg+')'}">
    <div class="content">
        <el-image fit="cover" style="width:352px;height: 198px;" :src="minImg" alt=""></el-image>
        <div class="content-info">
            <h3>{{ title }}</h3>
            <div v-html="info"></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BigMinBanner",
  props: {
    moduleDataSource: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      bigImg: "",
      minImg: "",
      title: "",
      info: "",
    };
  },
  mounted() {
    const { bigImg, minImg, title, info } = this.moduleDataSource;
    this.bigImg = bigImg;
    this.minImg = minImg;
    this.title = title;
    this.info = info;
  },
};
</script>

<style scoped>
.BigMinBanner{
    width:100%;
    height: 350px;
    background-repeat: no-repeat;
    background-size: 100% 350px;
}
    .content{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 350px;
        background-color: rgba(0, 0, 0, 0.4);
    }
    .content-info{
        margin-left:20px;
    }
    .content-info div{
        color:#fff;
        width:480px;
    }
    .content-info h3{
        color:#fff;
        font-size: 24px;
    }
</style>