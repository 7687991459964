<template>
  <div class="curveDistribution"></div>
</template>

<script>
export default {
name: "xyhtjEcharts",
props: {
  data: Object
},
data() {
  return {};
},
methods: {
  setChart() {
    let option = {
      title:{
        text:"户数",
        textStyle:{
          fontSize: '14px',
          color: '#333333',
          fontFamily:"PingFangSC-Semibold"
        }
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line"
        }
      },
      legend: {
        data: ['整村户数', '信用户'],
        right:10,
        top:0,
        textStyle:{
          color:'#666'
        }
      },
      grid: {
        left: "0%",
        top: "40",
        right: "0%",
        bottom: "0",
        containLabel: true
      },
      xAxis: [
        {
          type: "category",
          boundaryGap:true,
          data: this.data.name,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#ddd",
              width: 1,
              type: "solid"
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            // rotate:50,
            show: true,
            //splitNumber: 5,
            rotate:45,
            textStyle: {
              color: "#666",
              fontSize: "14"
            }
          },
        }
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999",
              fontSize: "12"
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,.1	)",
              width: 1,
              type: "solid"
            }
          },
          splitLine: {
            lineStyle: {
              color: "#ddd"
            }
          }
        }
      ],
      series: [
        {
          type: "line",
          name:'整村户数',
          //smooth: true,
          areaStyle: {
            normal: {
              color: new this.echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(238, 188, 40, 0.3)"
                  },
                  {
                    offset: 0.92,
                    color: "rgba(238, 188, 40, 0)"
                  }
                ],
                false
              ),
              shadowColor: "rgba(238, 188, 40, 0.1)"
            }
          },
          data: this.data.line1,
          itemStyle: {
            normal: {
              color: "#EEBC28",
              borderColor: "rgba(238, 188, 40, .1)",
              borderWidth: 12
            }
          }
        },
        {
          type: "line",
          //smooth: true,
          name:'信用户',
          areaStyle: {
            normal: {
              color: new this.echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(60, 160, 121, .3)"
                  },
                  {
                    offset: 0.92,
                    color: "rgba(60, 160, 121, 0)"
                  }
                ],
                false
              ),
              shadowColor: "rgba(60, 160, 121, 0.1)"
            }
          },
          data: this.data.line2,
          itemStyle: {
            normal: {
              color: "#3CA079",
              borderColor: "rgba(60, 160, 121, .1)",
              borderWidth: 12
            }
          }
        }
      ]
    };
    let myChart = this.$echarts(this.$el);
    myChart.clear();
    myChart.resize();
    myChart.setOption(option);
  }
},
mounted() {
  this.setChart();
},
 watch: {
  data: {
    deep: true, //深度监听
    handler() {
      this.setChart();
    },
  },
},
};
</script>

<style  scoped>
.curveDistribution {
width: 100%;
height: 100%;
}
</style>