<template>
	<div class="dkcpTab">
		<div class="container">
			<div class="top">
				<div>
					<img style="vertical-align: baseline;" src="@/assets/images/title_tips.png" alt="">
					<span class="ch">{{floorShowName}}</span>
				</div>
				 <div v-if="moduleLinkConfig">
				 	<a href="javascript:void(0);" @click="toList">
				 		<span class="more">{{moduleLinkConfig.more.buttonName}}</span>
				 		<i class="el-icon-arrow-right"></i>
				 	</a>
				 </div>
			</div>
			<div class="line">
				<p class="left"></p>
				<p class="right"></p>
			</div>
		</div>
		<div class="project" style="margin-top:24px;">
			<ul>
				<li @click="toDetail(item)" v-for="(item,index) in moduleDataSource" :key="item.id">
					<div :style="{backgroundImage:'url('+hzhbTabBgList[index%4]+')'}">
						<p class="bankName">{{ item.bankName }}</p>
						<p class="loanType">{{ item.loanType }}</p>
						<p class="interestRate">{{ item.interestRate }}<span>%/年</span> </p>
						<p class="txt">最高可贷{{ item.txt }}万元</p>
						<a href="javascript:void(0);" class="btn">立即申请 <i class="el-icon-caret-right"></i> </a>
					</div>

				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "HuaxianFinanceProject",
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			floorShowName: '',
			moduleTable: '',
			moduleLinkConfig: {}
		},
		data() {
			return {
				hzhbTabBgList: [
					require("@/assets/images/jrfw_p2_card1.png"),
					require("@/assets/images/jrfw_p2_card2.png"),
					require("@/assets/images/jrfw_p2_card3.png"),
					require("@/assets/images/jrfw_p2_card4.png"),
				]
			}
		},

		methods: {
			toList() {
				this.$emit('navitage', this.moduleLinkConfig.more, this.moduleTable)
			},
			toDetail(item){
				this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable,item)
			}
		}
	}
</script>

<style scoped>
	.dkcpTab{
		min-width: 1200px;
	}
	.dkcpTab .project ul {
		display: flex;
		/* justify-content: space-between; */
		flex-wrap: wrap;
	}
	.dkcpTab .project ul li{
		width:20%;
	}
	.dkcpTab .project ul li div{
		width: 224px;
		height: 240px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-bottom: 20px;
		padding: 24px 20px;
		box-sizing: border-box;

	}

	.dkcpTab .project .bankName {
		font-size: 14px;
		color: #666666;
		line-height: 20px;
		margin-bottom: 4px;
	}

	.dkcpTab .project .loanType {
		font-family: PingFangSC-Semibold;
		font-size: 20px;
		color: #333333;
		line-height: 28px;
		font-weight: 600;
		margin-bottom: 12px;
	}

	.dkcpTab .project .btn {
		background: #E8BF46;
		border-radius: 18px;
		width: 120px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		display: block;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #FFFFFF;
	}

	.dkcpTab .project .txt {
		font-size: 14px;
		color: #EE6B28;
		line-height: 20px;
		margin-bottom: 24px;
	}

	.dkcpTab .project .interestRate {
		font-family: PingFangSC-Semibold;
		font-size: 32px;
		color: #EE6B28;
		line-height: 40px;
		font-weight: 600;
	}

	.dkcpTab .project .interestRate span {
		font-size: 16px;
	}

	/* 标题 start */
	.container {
		width: 100%;
		height: 54px;
	}

	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}

	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}

	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3CA079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}

	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}

	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3CA079;
		margin-left: 4px;
	}

	.line {
		margin-top: 16px;
	}

	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}

	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}

	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}

	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}

	/* 标题 end */
</style>
