<template>
	<div class="footer">
		<div class="wrap-info">
			<div style="position: relative;">
				<p class="fontSize14 cfff">
					联系我们
				</p>
				<p class="fontSize14 cfff">
					邮箱：kefu@tpfg.com.cn
				</p>
				<p class="fontSize14 cfff">
					电话：010-59576628
				</p>
				<p class="fontSize14 cfff">
					地址：北京市朝阳门银河SOHO-D座6层50627
				</p>
				<img style="position:absolute;top:10px;left:300px;" src="@/assets/ewm.png" width="100px" height="100px" alt="">
			</div>
			<div>
				<p class="icp fontSize14 cfff"  @click="toGx">ICP备案号：京ICP备2024050735号-5</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Footer",
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {},
			},
		},
		methods:{
			toGx(){
				window.open('https://beian.miit.gov.cn/')
			}
		},
	};
</script>

<style scoped>
	.footer {
		/* height: 76px; */
		width: 100%;
		background: #333;
	}

	.wrap-info {
		width: 1180px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;
	}

	p {
		height: 28px;
		line-height: 28px;
	}
	.icp:hover{
		cursor: pointer;
	}
</style>