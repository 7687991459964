//配置信息
let url = location.hostname
if(url.startsWith('localhost')){
	url = 'http://tusjkweb.cccitech.com/tusjf-cloud-gateway'
}else{
	url = ''
}

export default{
	webUrl:url+'/tusjf-cloud-gateway',
	staticUrl:url+'/tusjf-cloud-gateway/sys/common/static'
}
