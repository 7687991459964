<template>
	<div class="container" v-show="loading">
		<el-row class="top">
			<el-col>
				<div class="grid-content bg-purple">
					<el-image :src="logoSrc" style="height:54px;float:left;"></el-image>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		getDetailByCustomNotAuth
	} from '@/api/api.js'
	export default {
		name: 'SiteHeader',
		props: {
			activeIndex: String
		},
		data() {
			return {
				logoSrc: '',
				loading:false
			};
		},
		mounted() {
			this.getLogo()
		},

		methods: {
			getLogo() {
				getDetailByCustomNotAuth('site_picture', {
					sys_org_code:this.SYSORGCODE,
					sysOrgCode:this.SYSORGCODE,
					fwTableId: 'site_picture',
					name_location:'pc网站logo'
				}).then(res => {
					if (res.success) {
						this.logoSrc = res.result.show_image
						//this.logoSrc = require('@/assets/gongan.png')
						this.$nextTick(()=>{
							this.loading = true
						})
					}
				})
			}
		},
	};
</script>

<style scoped>
	.container {
		height: 78px;
		width: 1200px;
		margin: 0 auto;
	}

	.top {
		width: 100%;
		height: 76px;
		display: flex;
		justify-content: space-between;

		padding-top: 20px;
	}

	span {
		color: #666;
		font-size: 14px;
	}

	span .line {
		margin: 0 8px;
		color: #ccc;
	}

	.el-menu--horizontal>.el-menu-item {
		height: 52px;
		line-height: 52px;
		font-size: 16px;
		border-bottom-width: 4px;
	}

	.el-menu-item {
		margin: 0 20px;
		padding: 0;
	}

	.el-menu-item a {
		display: block;
	}

	.el-menu--horizontal>.el-menu-item:first-child {
		margin-left: 0;
	}
</style>
