import Vue from 'vue'
import store from './store'
import router from './router'
//import router from '@/router'
// 引入配置
import './requireComponent'
import './permission'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './reset.css'
import './commom.css'
import VideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
import * as echarts from 'echarts'
Vue.use(VideoPlayer)

import App from './App.vue'
Vue.use(ElementUI)
// A22 滑县  公安  SJZX22
//Vue.prototype.SYSORGCODE = 'A22' // 数字滑州
Vue.prototype.SYSORGCODE = 'SJZX43' // 启迪金科
// Vue.prototype.SYSORGCODE = 'SJZX33' // 保定
//Vue.prototype.SYSORGCODE = 'SJZX59'	//永清
//Vue.prototype.SYSORGCODE = 'SJZX60'  //数字供应

//招商项目 立即咨询
//Vue.prototype.SENDSYSORGCODE = 'A21' //数字滑州
//Vue.prototype.SENDSYSORGCODE = 'SJZX59' //永清
//Vue.prototype.SENDSYSORGCODE = 'SJZX60'  //数字供应
Vue.prototype.SENDSYSORGCODE = 'SJZX43'// 启迪金科


//银行
Vue.prototype.BANKSYSORGCODE = 'A24'

//滑县门户 'szhz-portal'  gongan
// Vue.prototype.TENANTID = 'szhz-portal' // 数字滑州租户
Vue.prototype.TENANTID = 'tusjkweb' // 启迪金科租户
//Vue.prototype.TENANTID = 'baoding' //保定租户
//Vue.prototype.TENANTID = 'hbyq'	//河北永清
//Vue.prototype.TENANTID = 'szgx'  //数字供应
 
Vue.prototype.echarts = echarts
Vue.prototype.$echarts = function(el) {
	return echarts.init(el, null, { renderer: 'svg' })
}
Vue.prototype.removeHtmlStyle = function(html) {
	var newHtml = html
	newHtml = newHtml.replace(/\<img/gi, '<img class="rich-img" style="display:block" ');
	return newHtml
}
Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')