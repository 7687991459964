<template>
  <div class="content">
    <div class="slideTab fLeft">
      <el-menu active-text-color="#3CA079" class="el-menu-vertical-demo">
        <el-submenu index="1">
          <template slot="title">
            <span>公示公告</span>
          </template>
          <el-menu-item v-for="(item,index) in list" :key="item.cat_id" @click="menuSelect(index)" >{{item.cat_name}}<i class="el-icon-d-arrow-right"></i> </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <!-- 子级菜单 -->
    <div class="slideContentTab" v-for="item in subCategoryList" :key="item.cat_id">
      <div class="top">
        <div>
          <img
            style="vertical-align: baseline"
            src="../assets/images/title_tips.png"
            alt=""
          />
          <span class="ch">{{item.cat_name}}</span>
        </div>
      </div>
      <div class="line">
        <p class="left"></p>
        <p class="right"></p>
      </div>
      <div class="list" v-for="getlist in item.children" :key="getlist.cat_id">
        <div class="fRight">{{getlist.cat_name}}</div>
        <div class="data">{{getlist.time}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'HuaxianPublicity',
  data () {
    return {
      list: [
        {
          cat_id: 1,
          cat_name: '土地征收',
          children: [
            {
              cat_id: 2,
              cat_name: '土地征收',
              children: [
                {
                  cat_id: 3,
                  cat_name: '毛家港镇红光村集体土地征地补偿安置公告毛家港镇红光村集体土地征地补偿安置公告',
                  time: '2202-12-19'
                },
                {
                  cat_id: 4,
                  cat_name: '班竹垱镇伍家场村集体土地征地补偿安置公告班竹垱镇伍家场村集体土地征地补偿安置公告',
                  time: '2022-02-01'
                },
                {
                  cat_id: 5,
                  cat_name: '公安县2022年农作物秸秆综合利用重点县项目实施主体遴选公告公安县2022年农作物秸秆综合利用重点县项目实施主体遴选公告',
                  time: '2022-02-01'
                }
              ]
            }
          ]
        },
        {
          cat_id: 2,
          cat_name: '招聘信息',
          children: [
            {
              cat_id: 2,
              cat_name: '招聘信息',
              children: [
                {
                  cat_id: 3,
                  cat_name: '毛家港镇红光村集体土地征地补偿安置公告毛家港镇红光村集体土地征地补偿安置公告',
                  time: '2202-12-19'
                },
                {
                  cat_id: 4,
                  cat_name: '毛家港镇红光村集体土地征地补偿安置公告毛家港镇红光村集体土地征地补偿安置公告',
                  time: '2202-12-19'
                },
                {
                  cat_id: 5,
                  cat_name: '毛家港镇红光村集体土地征地补偿安置公告毛家港镇红光村集体土地征地补偿安置公告',
                  time: '2202-12-19'
                }
              ]
            }
          ]
        },
        {
          cat_id: 3,
          cat_name: '食品处置',
          children: [
            {
              cat_id: 2,
              cat_name: '食品处置',
              children: [
                {
                  cat_id: 3,
                  cat_name: '毛家港镇红光村集体土地征地补偿安置公告毛家港镇红光村集体土地征地补偿安置公告',
                  time: '2202-12-19'
                },
                {
                  cat_id: 4,
                  cat_name: '毛家港镇红光村集体土地征地补偿安置公告毛家港镇红光村集体土地征地补偿安置公告',
                  time: '2202-12-19'
                },
                {
                  cat_id: 5,
                  cat_name: '毛家港镇红光村集体土地征地补偿安置公告毛家港镇红光村集体土地征地补偿安置公告',
                  time: '2202-12-19'
                }
              ]
            }
          ]
        }
      ],
      // 记录当前点击了哪个一级分类
      activeIndex: 0
    }
  },
  computed: {
    // 根据索引的变化动态获取二级分类
    subCategoryList () {
      // 如果分类的数组长度为0时，那么人为指定二级分类为空数组
      if (this.list.length === 0) return []
      return this.list[this.activeIndex].children
    }
  },
  methods: {
    menuSelect (index) {
      console.log(index)
      this.activeIndex = index
    }
  }
}
</script>

<style scoped>
.content {
  width: 1200px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}
.slideTab {
  width: 279px;
  height: 200px;
}
.slideTab ul > li .title {
  height: 44px;
  background: #3ca079;
  border-radius: 2px;
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  line-height: 44px;
}

.slideTab ul > li > ul > li {
  line-height: 40px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #666666;
  text-align: left;
  padding: 0;
  padding-left: 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slideTab ul > li > ul > li:hover {
  background: #ebf5f1;
}

.slideTab ul > li > ul > li:hover .el-icon-d-arrow-right {
  display: block;
}

.el-icon-d-arrow-right {
  font-size: 16px;
  display: none;
}
::v-deep .el-menu {
  background-color: #fafafa;
  border-right: none;
}
.el-submenu .el-menu-item {
  height: 40px;
  width: 100%;
  padding-left: 20px;
}

.slideTab ul > li > ul > li.is-active {
  background: #ebf5f1;
}

.slideTab ul > li > ul > li.is-active .el-icon-d-arrow-right {
  display: block;
}

.slideContentTab {
  width: 960px;
}

::v-deep .el-submenu .el-submenu__title {
  height: 44px;
  background: #3ca079;
  border-radius: 2px;
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  line-height: 44px;
  text-align: left;
}
.slideContentTab {
  width: 850px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}
.list {
  width: 100%;
  display: flex;

  margin-bottom: 4px;
}
.fRight {
  width: 740px;
  height: 36px;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis; 
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 36px;
  cursor: pointer;
}
.data {
  flex: 1;
  color: rgb(74, 74, 74);
  font-size: 16px;
  text-align: center;
  line-height: 36px;
}
.ch {
  font-family: PingFangSC-Medium;
  font-size: 28px;
  color: #181818;
  line-height: 36px;
  font-weight: 500;
  margin-left: 10px;
}
.line {
  margin-top: 16px;
  margin-bottom: 47px;
}

.line .left {
  width: 200px;
  height: 2px;
  background-color: #3ca079;
  float: left;
}

.line .right {
  width: calc(100% - 226px);
  height: 1px;
  background-color: #cee7dd;
  float: right;
  position: relative;
}

.line .right::before {
  width: 20px;
  height: 2px;
  background-color: #3ca079;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
}

.line .right::after {
  width: 20px;
  height: 2px;
  background-color: #3ca079;
  position: absolute;
  content: "";
  right: 0;
  top: 0;
}
</style>
