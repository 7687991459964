import {
	login,
	logout,
	getInfo
} from '@/api/user'
import {
	getToken,
	setToken,
	removeToken
} from '@/utils/auth'
import router, {
	resetRouter
} from '@/router'

const state = {
	token: getToken(),
	name: '',
	avatar: '',
	introduction: '',
	roles: []
}

const mutations = {
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_INTRODUCTION: (state, introduction) => {
		state.introduction = introduction
	},
	SET_NAME: (state, name) => {
		state.name = name
	},
	SET_AVATAR: (state, avatar) => {
		state.avatar = avatar
	},
	SET_ROLES: (state, roles) => {
		state.roles = roles
	}
}

const actions = {
	login({
		commit
	}, userInfo) {
		const {
			username,
			password
		} = userInfo
		return new Promise((resolve, reject) => {
			login({
				username: username.trim(),
				password: password
			}).then(response => {
				const {
					data
				} = response
				commit('SET_TOKEN', data.token)
				setToken(data.token)
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},
	getInfo({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			getInfo(state.token).then(response => {
				const {
					data
				} = response
				if (!data) {
					reject('验证失败！请重新的登录！')
				}
				const {
					roles,
					name,
					avatar,
					introduction
				} = data
				
				if(!roles || roles.length<=0){
					reject('角色必须是非空数组！')
				}
				
				commit('SET_ROLES',roles)
				commit('SET_NAME',name)
				commit('SET_AVATAR',avatar)
				cmmmit('SET_INTRODUCTION',introduction)
				resolve(data)
			}).catch(error=>{
				reject(error)
			})
		})
	},
	logout({commit,state,dispatch}){
		return new Promise((resolve,reject)=>{
			logout(state.token).then(()=>{
				commit('SET_TOKEN','')
				commit('SET_ROLES',[])
				removeToken()
				resetRouter()
				//重置已访问的视图和缓存的视图
				// to fixed issues/2485
				dispatch('tagsView/delAllViews', null, { root: true })
				resolve()
			}).catch(error=>{
				reject(error)
			})
		})
	},
	resetToken({commit}){
		return new Promise(resolve=>{
			commit('SET_TOKEN','')
			commit('SET_ROLES',[])
			removeToken()
			resolve()
		})
	},
	async changeRoles({commit,dispatch},role){
		const token = role + '-token'
		commit('SET_TOKEN',token)
		setToken(token)
		const {roles} = await dispatch('getInfo')
		resetRouter()
		//根据角色生成路由
		const accessRoutes = await dispatch('permission/generateRoutes',roles,{root:true})
		//动态添加可访问的路由
		router.addRoutes(accessRoutes)
		//重置已访问的视图和缓存的视图
		dispatch('tagsView/delAllViews',null,{root:true})
	}
}

export default{
	namespaced:true,
	state,
	mutations,
	actions
}