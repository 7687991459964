<template>
  <div class="container">
    <div class="title1" v-html="moduleDataSource.info"></div>
    <div class="title2">{{moduleDataSource.title}}</div>
    <img :src="moduleDataSource.img" alt="" style="width: 100%; height: 100%" />
  </div>
</template>
<script>
export default {
  name: 'TuiJian',
  props: {
    moduleDataSource: {
    	type: Object,
    	default: () => {}
    },
  },
  data () {
    return {
    }
  },
  methods:{
	  
  }
}
</script>

<style scoped>
.container{
  position: relative;
  height: 273px;
}
.title1 {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    position: absolute;
    background-image: linear-gradient(to right, #05d4e2 , #003444);
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    text-align: center;
    line-height: 50px;
  }
  .title2 {
    position: absolute;
    width: 75%;
    height: 50px;
    left: 0;
    bottom: 50px;
    background-image: url('@/assets/images/tuijian_line.png');
    text-align: center;
    color: rgba(255, 255, 255, 1);
    font-size: 28px;
    line-height: 50px;
    background-repeat:no-repeat ;
  }
</style>
