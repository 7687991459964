<template>
	<div class="container">
		<div class="banner">
			<!-- <el-image :src="moduleDataSource.img"></el-image> -->
			<template v-if="bannerList.length==1">
				<el-row>
					<el-col :span="24" style="height: 400px;"><el-image style="width: 100%; height: 400px" :src="getImgSrc(bannerList[0])"></el-image></el-col>
				</el-row>
			</template>
			<template v-else>
				<el-carousel height="400px">
					<el-carousel-item v-if="item" v-for="(item,index) in bannerList" :key="index">
						<el-image style="width: 100%; height: 400px" :src="getImgSrc(item)"></el-image>
					</el-carousel-item>
				</el-carousel>
			</template>
			<div class="txt">
				<div class="inner">
					<p class="title" v-html="moduleDataSource.title"></p>
					<p class="en" v-if="moduleDataSource.dataGroup" v-html="moduleDataSource.dataGroup"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getFileAccessHttpUrl
	} from '@/utils/util.js'
	export default {
		name: 'HuaxianBanner',
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {}
			},
		},
		data(){
			return {
				bannerList:[]
			}
		},
		methods:{
			getImgSrc(url) {
				return getFileAccessHttpUrl(url)
			}
		},
		mounted(){
			let imgArr = this.moduleDataSource.img
			if(imgArr.indexOf(',')>-1){
				imgArr = imgArr.split(',')
				imgArr.forEach(item=>{
					this.bannerList.push(item)
				})
			}else{
				this.bannerList.push(imgArr)
			}
		}
	};
</script>

<style scoped>
	.banner {
		position: relative;
		min-width: 1300px;
	}

	.font14 {
		font-size: 14px;
	}

	.font16 {
		font-size: 16px;
	}

	.el-image img {
		width: 100%;
		min-height: 200px;
		display: block;
	}

	.txt {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		top: 0;
		z-index:999;
	}

	.inner {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}

	.title {
		font-family: AlimamaShuHeiTi;
		font-size: 40px;
		color: #FFFFFF;
		margin-bottom: 16px;
		font-weight: bold;
	}

	.en {

		color: rgba(255, 255, 255, 0.85);
		text-transform: uppercase;
	}
</style>
