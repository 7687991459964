import config from '../api/config.js';

/**
 * 获取文件服务访问路径
 * @param avatar
 * @param subStr
 * @returns {*}
 */
export function getFileAccessHttpUrl(avatar,subStr) {
  if(!subStr) subStr = 'http'
  if(avatar && avatar.startsWith(subStr)){
    return avatar;
  }else{
    if(avatar &&　avatar.length>0 && avatar.indexOf('[')==-1){
      return config.staticUrl + "/" + avatar;
    }
  }
}

/**
 * 数据处理  对字段进行分组
 * @param arr [Array] 被处理的数组
 * @param group_key [String] 分组字段
*/
export function formattingData (arr, group_key){
    let map = {}
    let res = []
    for (let i = 0; i < arr.length; i++) {
        let ai = arr[i]
        if (!map[ai[group_key]]) {
            map[ai[group_key]] = [ai]
        } else {    
            map[ai[group_key]].push(ai)
        }
    }
    Object.keys(map).forEach(key => {
        res.push({
            [group_key]: key,
            list: map[key],
        })
    })

    return res
}