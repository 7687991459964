<template>
	<div class="other">
		<p class="title">{{floorShowName}}</p>
		<ul>
			<li v-for="(item,index) in moduleDataSource" :key="index">
				<a @click="toDetail(item)" href="JavaScript:;">{{ item.title }}</a>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'HuaxianOtherNews',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			floorShowName: '',
			moduleTable: '',
			moduleLinkConfig: {}
		},
		methods: {
			toDetail(item) {
				this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable, item)
			}
		}
	}
</script>

<style scoped>
	.other {
		border: solid 1px #eee;
		line-height: 2.2;
		padding: 20px 30px 20px;
	}

	.other .title {
		font-size: 20px;
		text-align: left;
	}

	.other li {
		white-space: nowrap;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
