<template>
	<div class="breadcrumb">
		<div style="width: 1200px;margin:0 auto;">
			<el-breadcrumb separator="/">
				<template v-for="(item,index) in list">
					<el-breadcrumb-item v-if="index<list.length-1" :to="{ path: item.btnOpenPage }">
						{{item.btnName}}
					</el-breadcrumb-item>
					<el-breadcrumb-item v-else>
						{{item.btnName}}
					</el-breadcrumb-item>
				</template>
			</el-breadcrumb>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HuaxianBreadcrumb',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
		},
		data() {
			return {
				list: []
			}
		},
		mounted() {
			let arr = JSON.parse(this.moduleDataSource[0].pageButtonConf)
			arr.forEach((item, index) => {
				this.$set(this.list, index, item)
			})
		},
		methods: {
			toLink(item) {
				conosle.log(item)
			}
		}
	}
</script>

<style>
</style>
