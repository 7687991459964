<template>
	<div class="xwzxTab">
		<div class="container">
			<div class="top">
				<div>
					<img style="vertical-align: baseline" src="@/assets/images/title_tips.png" alt="" />
					<span class="ch">{{ floorShowName }}</span>
				</div>
				<div v-if="moduleLinkConfig&&moduleLinkConfig.more&&moduleLinkConfig.more.linkOpenUrl">
					<a href="javascript:void(0);" @click="toList">
						<span class="more">{{ moduleLinkConfig.more.buttonName }}</span>
						<i class="el-icon-arrow-right"></i>
					</a>
				</div>
			</div>
			<div class="line">
				<p class="left"></p>
				<p class="right"></p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ColumnName',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => [],
			},
			floorShowName: "",
			moduleTable: "",
			moduleLinkConfig: {},
		},
		methods: {
			toList() {
				this.$emit("navitage", this.moduleLinkConfig.more, this.moduleTable);
			},
		}
	}
</script>

<style scoped>
	.xwzxTab {
		width: 1200px;
	}

	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}

	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}

	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3ca079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}

	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}

	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3ca079;
		margin-left: 4px;
	}

	.line {
		margin-top: 16px;
	}

	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}

	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}

	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}

	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}
</style>