<template>
	<div class="infoPublicity">
	    <div class="fLeft">
	        <img src="@/assets/images/dj_xxgs_img.png" alt="" style="float:left;">
	        <el-carousel height="56px" direction="vertical" :interval="5000">
	            <el-carousel-item v-for="(item,index) in list" :key="index">
	            [{{ item.village }}]完成数据采集{{item.people}}人
	            </el-carousel-item>
	        </el-carousel>
	    </div>
	    <div class="fRight">
	          <!--  <span class="more" >更多</span>
	            <i class="el-icon-arrow-right"></i> -->
	    </div>
	</div>
</template>

<script>
	export default{
		name:'HuaxiannewsRotation',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
		},
		data() {
			return {
				list:[]
			}
		},
		mounted() {
			console.log(this.moduleDataSource)
			this.moduleDataSource.forEach(item=>{
				console.log(item)
				if(item.people>0){
					this.list.push(item)
				}
			})
			console.log(this.list)
		}
	}
</script>

<style scoped>
	.infoPublicity{
	    padding:0 20px;
	    height:56px;
	    background-color: #fff;
	    position: relative;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    border: 1px solid #EEEEEE;
	    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.05);
	    border-radius: 4px;
		width:1200px;
		margin:0 auto;
		box-sizing: border-box;
	}
	.infoPublicity .more{
	    font-size: 14px;
	    color: #666666;
	}
	.infoPublicity .fLeft{
	    display: flex;
	    align-items: center;
	}
	.infoPublicity .el-icon-arrow-right{
	    font-size: 16px;
	    color: #3CA079;
	    margin-left:4px;
	}
	.infoPublicity .fRight a{
	    display: flex;
	    align-items: center;
	}
	.el-carousel{
	    width:400px;
	    margin-left:20px;
	    line-height: 56px;
	    color:#666;
	}
</style>