<template>
	<div class="tab xymfTab">
		<div class="xymfTitle">
			<span class="ch">信用模范</span>
		</div>
		<div class="content">
			<ul>
				<li v-for="(item,index) in moduleDataSource" :key="index">
					<div style="display:flex;">
						<div class="icon">
							0{{index+1}}
						</div>
						<div>
							<p class="t1">{{ item.depart_name }}</p>
							<p class="txt1">
								<span>{{ formatName(item.farmer_xm) }}</span>
								<span>信用评分 {{ item.credit_core }}</span>
								<!-- <span>补充项得分 {{ item.supplement }}</span> -->
							</p>
						</div>

					</div>
					<div>
						<p class="t2">{{item.credit_level}}</p>
						<p class="txt2">测评等级</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HuaxianCreditModel',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
		},
		methods: {
			formatName(name) {
				var newStr;
				if (name.length === 2) {
					newStr = name.substr(0, 1) + '*';
				} else if (name.length > 2) {
			 	var char = '';
					for (var i = 0, len = name.length - 2; i < len; i++) {
						char += '*';
					}
					newStr = name.substr(0, 1) + char + name.substr(-1, 1);
				} else {
					newStr = name;
				}
			 return newStr;
			}
		}
	}
</script>

<style scoped>
	.content {
		padding: 0 24px;
	}

	.xymfTitle {
		background-image: linear-gradient(270deg, #6FCEB0 0%, #3CA079 100%);
		border-radius: 4px;
		line-height: 80px;
		height: 80px;
		padding-left: 24px;
	}

	.ch {
		font-family: PingFangSC-Semibold;
		font-size: 24px;
		color: #FFFFFF;
		font-weight: bold;
		margin-right: 8px;
	}

	.xymfTab {
		background: #FFFFFF;
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
		border-radius: 4px;
	}

	.xymfTab li {
		height: 84px;
		background: rgba(255, 255, 255, 0.00);
		border-bottom: solid 1px #f5f5f5;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.xymfTab li .t1 {
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #333333;
		line-height: 24px;
		margin-bottom: 4px;
		font-weight: 500;
	}

	.xymfTab li .icon {
		width: 60px;
		height: 54px;
		background: url("@/assets/images/dj_xyxz_icon.png") no-repeat;
		background-size: 100% 100%;
		font-size: 24px;
		color: #FFFFFF;
		text-align: center;
		line-height: 54px;
		font-weight: bold;
		margin-right: 16px;
	}

	.xymfTab li .t2 {
		font-family: PingFangSC-Semibold;
		font-size: 20px;
		color: #333333;
		line-height: 24px;
		font-weight: 600;
		margin-bottom: 4px;
	}

	.xymfTab li .txt1 span {
		margin-right: 15px;
	}

	.xymfTab li .txt1 {
		font-size: 14px;
		color: #666666;
		line-height: 20px;
	}

	.xymfTab li .txt2 {
		font-size: 14px;
		color: #999999;
		line-height: 20px;
	}
</style>
