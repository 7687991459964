<template>
	<div class="w1200">
		<div class="classificationTab" style="padding-top: 0;">
			<p class="title" v-if="floorShowName">{{ floorShowName }}</p>
			<ul>
				<li v-for="(item, index) in classificationList" :key="index">
					<span class="name">{{ item.name }}</span>
					<span :class="item1.active ? 'type' : ''" @click="search(item1, index1, index)" class="list"
						v-for="(item1, index1) in item.list" :key="index1">{{ item1.title }}</span>
				</li>
			</ul>
		</div>
		<div v-if="moduleDataSource.length > 0">
			<div class="zsxmTab">
				<ul>
					<li v-for="item in moduleDataSource" :key="item.id" @click="openinfo(item)">
						<a href="javascript:void(0)">
							<div class="img">
								<el-image :src="item.img"></el-image>
							</div>
							<div class="txt">
								<p class="t fontOverHidden">{{ item.title }}</p>
								<p class="area"><span>所属区域：</span>{{ item.area }}</p>
								<p class="type"><span>{{ moduleTable === 'merchants_land_info' ? '土地' : '项目' }}类型：</span>{{
									item.projectType }}</p>
								<p class="date"><span>发布日期：</span>{{ item.date }}</p>
							</div>
						</a>
					</li>
				</ul>
			</div>
			<div class="pagination">
				<el-pagination :current-page="page" @current-change="handleCurrentChange" layout="prev, pager, next"
					:total="total">
				</el-pagination>
			</div>
		</div>
		<el-empty style="margin-bottom: 120px;" v-else description="暂无记录"></el-empty>
	</div>
</template>
<script>
export default {
	name: 'HuaxianAttractProjectList',
	props: {
		moduleDataSource: {
			type: Array,
			default: () => []
		},
		modulePagination: {},
		moduleLinkConfig: {},
		moduleTable: '',
		floorId: {
			type: String,
			default: ''
		},
		moduleItem: {
			type: Object
		},
		floorShowName: ''
	},
	data() {
		return {
			page: 1,
			pageSize: this.modulePagination.pageSize,
			total: this.modulePagination.total,
			list: [],
			params: {},
			classificationList: [
				{
					name: '项目类型：',
					list: [{
						title: '全部',
						key: 'project_type',
						value: '',
						active: true,
					}, {
						title: '文旅综合项目',
						key: 'project_type',
						value: '1',
						active: false,
					}, {
						title: '盘活闲置',
						key: 'project_type',
						value: '2',
						active: false,
					}, {
						title: '农业园区',
						key: 'project_type',
						value: '3',
						active: false,
					}, {
						title: '其他项目',
						key: 'project_type',
						value: '4',
						active: false,
					}]
				}, {
					name: '发布日期：',
					list: [{
						title: '全部',
						key: 'issueDate',
						start: '',
						end: '',
						active: true,
					}, {
						title: '近1个月',
						key: 'issueDateScope',
						start: 0,
						end: 1,
						active: false,
					}, {
						title: '1-3个月',
						key: 'issueDateScope',
						start: 1,
						end: 3,
						active: false,
					}, {
						title: '4-6个月',
						key: 'issueDateScope',
						start: 4,
						end: 6,
						active: false,
					}, {
						title: '7-12个月',
						key: 'issueDateScope',
						start: 7,
						end: 12,
						active: false,
					}, {
						title: '1年以上',
						key: 'issueDateScope',
						start: 1,
						end: 100,
						active: false,
					}]
				}
			],
			issue_date_begin: '',
			issue_date_end: ''
		};
	},
	watch: {
		modulePagination: {
			handler(newVal) {
				this.total = newVal.total
			},
			deep: true
		},
		moduleDataSource: {
			handler(newVal) {
				this.list = newVal
			},
			deep: true
		}
	},
	created() {
		this.list = this.moduleDataSource
		this.initSearch()
	},
	methods: {
		async initSearch() {
			if (this.moduleTable === 'merchants_land_info') {
				this.classificationList[0] = {
					name: '土地性质：',
					list: [
						{
							title: '全部',
							key: 'merchants_land_type',
							value: '',
							active: true,
						},
						{
							title: '耕地',
							key: 'merchants_land_type',
							value: '1',
							active: false,
						}
					]
				}
			}
		},
		handleCurrentChange(val) {
			this.page = val
			this.$emit('getPageList', val, this.moduleItem, this.floorId)
		},
		openinfo(item) {
			this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable, item)
		},
		search(item, index, parentIndex) {
			let key = item.key
			let start = item.start
			let end = item.end
			this.classificationList[parentIndex].list.forEach(item => {
				item.active = false
			})
			item.active = true
			//查询
			if ('issueDate' == key) {
				this.issue_date_begin = ''
				this.issue_date_end = ''
			}
			if ('issueDateScope' == key) {
				let tiem = new Date();
				var year = tiem.getFullYear(); //获取当前N年的时间
				var mon = tiem.getMonth() + 1;
				var oldMon = tiem.getMonth() + 1
				if (mon < 10) {
					mon = '0' + mon
				}
				var day = tiem.getDate();
				if (day < 10) {
					day = '0' + day
				}
				/* if () {
					this.issue_date_begin = this.getToday(new Date(), end)
					this.issue_date_end = year + '-' + mon + '-' + day
				} */
				if (index == 1 || index == 2 || index == 3 || index == 4) {
					//如果传进来的月份大于当前月  进行夸年
					this.issue_date_begin = this.getToday(new Date(), 0, 0, end)
					this.issue_date_end = this.getToday(new Date(), 0, 0, start)
				}
				if (index == 5) {
					this.issue_date_begin = this.getToday(new Date(), 0, 100, 0)
					this.issue_date_end = this.getToday(new Date(), 0, 1, 0)
				}
			}
			if (this.moduleTable === 'merchants_land_info') {
				this.moduleItem.componenConfig.attribute.queryParams.push({
					key: 'merchants_land_fbrq_begin',
					value: this.issue_date_begin
				}, {
					key: 'merchants_land_fbrq_end',
					value: this.issue_date_end
				})
			} else {
				this.moduleItem.componenConfig.attribute.queryParams.push({
					key: 'issue_date_begin',
					value: this.issue_date_begin
				}, {
					key: 'issue_date_end',
					value: this.issue_date_end
				})
			}
			this.moduleItem.componenConfig.attribute.queryParams.push({
				key: item.key,
				value: item.value
			})
			this.page = 1
			this.$emit('getPageList', 1, this.moduleItem, this.floorId)
		},
		getToday(n, ds = 0, ys = 0, ms = 0) {
			/* 
			n 是当前时间，
			ds是需要获取的前N天
			ys是需要获取的前N年
			ms是需要获取的前N月 
			 */
			var now = new Date(n);
			var time = now - 24 * 60 * 60 * 1000 * ds; //获取前N天
			var d = new Date(time);
			var year = d.getFullYear() - ys; //获取当前N年的时间
			var mon = d.getMonth() + 1;
			if (mon <= ms) {
				year = year - 1;
				mon = 12 - (ms - mon)
			} else {
				mon = mon - ms
			}
			var day = d.getDate();
			var week = d.getDay() - 1;
			var hour = d.getHours();
			var secd = d.getMinutes();
			var week = d.getDay();
			var times = ''
			if (mon == 0) {
				times = 12 + '-' + (day < 10 ? ("0" + day) : day)
			} else if (mon < 10) {
				times = (mon < 10 ? ('0' + mon) : mon) + '-' + (day < 10 ? ('0' + day) : day)
			} else {
				times = mon + '-' + (day < 10 ? ("0" + day) : day)
			}
			var today = year + '-' + times;
			console.log(today)
			return today
		},
		/* search(item, index) {
			this.classificationList[index].list.forEach(item => {
				item.active = false
			})
			item.active = true
			this.moduleItem.componenConfig.attribute.queryParams.push({
				key:item.key,
				value:item.value
			})
			this.$emit('getPageList', 1, this.moduleItem, this.floorId)
		} */
	},
};
</script>
<style scoped>
.pagination {
	text-align: center;
}

.dkcpTab {
	border-bottom: solid 1px #eee;
	padding-bottom: 20px;
}

.dkcpTab ul {
	display: flex;
	flex-wrap: wrap;
}

.dkcpTab ul li {
	width: 384px;
	height: 322px;
	background: #FFFFFF;
	border: 1px solid #eee;
	border-radius: 4px;
	padding: 35px 21px 0;
	box-sizing: border-box;
	margin-bottom: 24px;
	margin-right: 24px;
}

.dkcpTab ul li:nth-child(3n+3) {
	margin-right: 0;
}

.dkcpTab ul li .name {
	font-size: 14px;
	color: #666666;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dkcpTab ul li .type {
	font-size: 24px;
	color: #333333;
	font-weight: bold;
	margin: 12px 0 17px;
	text-align: center;
}

.dkcpTab ul li .info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
}

.dkcpTab ul li .info .vertical {
	display: flex;
	flex-direction: column;
	width: 112px;
}

.dkcpTab ul li .name .el-image {
	/* width:22px;
        margin-right:10px; */
	height: 20px;
}

.dkcpTab ul li .info .num {
	font-size: 28px;
	color: #EE6B28;
}

.dkcpTab ul li .info .num em {
	font-style: normal;
	font-size: 14px;
}

.dkcpTab ul li .info .txt {
	font-size: 14px;
	color: #333333;
}

.dkcpTab ul li .line {
	width: 1px;
	height: 40px;
	background-color: #eee;
}

.dkcpTab ul li .info .vertical .des {
	font-size: 14px;
}

.dkcpTab ul li a.btn {
	width: 160px;
	height: 40px;
	background: #FFFFFF;
	border: 1px solid #3CA079;
	border-radius: 20px;
	display: block;
	text-align: center;
	line-height: 40px;
	font-size: 14px;
	color: #3CA079;
	font-weight: 500;
	margin: 23px auto;
}

.dkcpTab ul li .target {
	border-top: solid 1px #eee;
	background: #FAFAFA;
	font-size: 14px;
	color: #333333;
	text-align: center;
	margin-left: -21px;
	margin-right: -21px;
	line-height: 48px;
}

.dkcpTab ul li .target span {
	color: #999;
}

.dkcpTab ul li:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.10);
}

.dkcpTab ul li:hover a.btn {
	background: #3CA079;
	;
	color: #fff;
}

/deep/ .el-pagination {
	margin-bottom: 0;
}

.list:hover {
	cursor: pointer;
}
.zsxmTab{
	min-height: 340px;
}
.zsxmTab li a {
	display: flex;
}

.zsxmTab {
	border-bottom: solid 1px #eee;
	padding-bottom: 20px;
}

.zsxmTab ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.zsxmTab li {
	width: 48%;
	margin-bottom: 24px;
}

.zsxmTab li .img .el-image {
	width: 256px;
	height: 144px;
}

.zsxmTab li .img {
	margin-right: 20px;
}

.zsxmTab li:hover .t {
	color: #3CA079;
}

.zsxmTab li p {
	font-size: 14px;
	color: #666;
	margin-bottom: 8px;
}

.zsxmTab li p.date {
	margin-bottom: 0;
}

.zsxmTab li p span {
	color: #999;
}

.zsxmTab li .t {
	font-family: PingFangSC-Medium;
	font-size: 20px;
	color: #181818;
	font-weight: 500;
	margin-bottom: 18px;
	padding-top: 5px;
	width: 308px;
}
</style>
