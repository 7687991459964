import axios from "axios";
import qs from "qs";
import config from '../api/config.js';
import {
	Loading
} from 'element-ui'
import {
	Message
} from 'element-ui'

//当前请求的个数
let requestCount = 0;
//加载效果的实例
let loadingInstance = null


// 创建 axios 实例
const service = axios.create({
	baseURL: config.webUrl, // api base_url
	timeout: 60000 // 请求超时时间
})
const err = (error) => {
	if (loadingInstance) {
		loadingInstance.close()
	}
	if (error.response) {
		let data = error.response.data
		switch (error.response.status) {
			case 403:
				Message({
					message: '拒绝访问',
					grouping: true,
					type: 'error',

				})
				break
			case 404:
				Message({
					message: '很抱歉，资源未找到!',
					grouping: true,
					type: 'error',

				})
				break
			case 504:
				Message({
					message: '网络超时!',
					grouping: true,
					type: 'error',

				})
				break
			case 401:
				Message({
					message: '未授权!',
					grouping: true,
					type: 'error',

				})
				break
			default:
				Message({
					message: data.message || data,
					grouping: true,
					type: 'error',

				})
				break
		}
	}
	return Promise.reject(error)
};
//post请求头
service.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

service.interceptors.request.use(
	config => {
		//requestCount=0 表示当前没有请求开启加载效果，则马上开启加载效果
		if (requestCount === 0) {
			loadingInstance = Loading.service()
		}
		//每次请求计数+1
		requestCount += 1
		config.params = {
			delFlag: 0,
			del_flag: 0,
			_t: Date.parse(new Date()) / 1000,
			...config.params
		}
		return config;
	},
	error => {
		// 当出现异常的时候计数-1
		requestCount -= 1;
		if (requestCount <= 0 && loadingInstance) {
			requestCount = 0;
			loadingInstance.close()
		}
		return Promise.reject(error);
	}
);

service.interceptors.response.use(
		response => {
			// 当请求返回的时候计数-1
			requestCount -= 1;
			// 当加载实例存在且计数小于等于0的时候把加载效果关闭
			if (requestCount <= 0 && loadingInstance) {
					requestCount = 0;
					loadingInstance.close()
				}
				return response.data
			}, err => {
				// 当响应出现异常的时候计数-1
				requestCount -= 1;
				// 当加载实例存在且计数小于等于0的时候把加载效果关闭
				if (requestCount <= 0 && loadingInstance) {
					requestCount = 0;
					loadingInstance.close();
				}
				if(err.response.status == 500){
					this.$messge.error(err.response.data)
				}
				console.log(err)
				// ....
				return Promise.reject(err);
			});
		export {
			service as axios
		}