<template>
	<div class="recommendTab">
	    <div class="w1200">
	        <div class="tTitle">
	            <span class="line"></span>
	            <span>{{floorShowName}}</span> 
	            <span class="line"></span>
	        </div>
	        <ul>
	            <li v-for="(item,index) in moduleDataSource" :key="index">
	                <a href="javascript:void(0);" @click="toDetail(item)">
	                    <p class="title">{{ item.title }}</p>
	                    <p class="date">{{ item.date }}</p>
	                </a>
	            </li>
	        </ul>
	    </div>
	</div>
</template>

<script>
	export default{
		name:'HuaxianOtherFinance',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			moduleTable:'',
			moduleLinkConfig:{},
			floorShowName:''
		},
		methods:{
			toDetail(item){
				this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable, item)
			}
		}
	}
</script>

<style scoped>
	.recommendTab {
	    background: #f7f7f7;
	    padding: 24px 0 40px;
	}
	.w1200 {
	    width: 1200px;
	    margin: 0 auto;
	}
	.tTitle {
	    font-family: PingFangSC-Semibold;
	    font-size: 24px;
	    color: #3CA079;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    margin-bottom: 40px;
	}
	.tTitle .line {
	    width: 529px;
	    height: 1px;
	    background-color: #3CA079;
	}
	.recommendTab ul {
	    display: flex;
	    justify-content: space-between;
	    flex-wrap: wrap;
	}
	.recommendTab ul li {
	    width: 48%;
	    margin-bottom: 10px;
	}
	.recommendTab ul li a {
	    display: flex;
	    justify-content: space-between;
	}
	.recommendTab ul li .title {
	    color: #333;
	    flex: 1;
	    white-space: nowrap;
	    display: block;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    font-size: 16px;
	    text-align: left;
	    margin-bottom: 0;
	}
	.recommendTab ul li .date {
	    color: #999;
	    font-size: 14px;
	    width: 180px;
	    margin-bottom: 0;
	}
</style>