<template>
	<div class="zsxmTab">
		<div class="box" v-for="item in moduleDataSource" :key="item.id">
			<div :class="item.title == undefined ? 'tab1':'tab2'">
				<a href="javascript:void(0);" @click="toDetail(item)">
					<img :src="item.img" alt="" />
				</a>
			</div>
			<div class="title" @click="toDetail(item)">
				<a>{{item.title}}</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Quicknavigation',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => [],
			},
			floorShowName: "",
			moduleTable: "",
			moduleLinkConfig: {},
		},
		created() {
			console.log(this.moduleDataSource, '执行了')
		},
		methods: {
			toDetail(item) {
				if (item.page_button_conf && JSON.parse(item.page_button_conf).length > 0) {
					let button = JSON.parse(item.page_button_conf)[0]
					console.log(button)
					if (button.linkType === 'linkTypeIn') {
						this.$router.push(button.btnOpenPage)
					} else {
						window.open(button.linkOpenUrl)
					}
				} else {
					this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable, item)
				}
			}
		}
	}
</script>

<style scoped>
	.zsxmTab {
		width: 1200px;
		display: flex;
		/* 	align-items: stretch; */
		flex-wrap: wrap;
	}

	.box {
		width: 130px;
		height: 130px;
		margin-right: 84px;
		/* 第四个参数：扩张半径，根据指定的值去扩大或缩小投影的尺寸 */
		box-shadow: 2px 3px 5px 2px rgba(0, 0, 0, .1);
		border-radius: 10px;
		margin-bottom: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.box:nth-child(6n) {
		margin-right: 0;
	}

	.tab1 {
		width: 110px;
		height: 110px;
		overflow: hidden;
	}

	.tab2 {
		width: 85px;
		height: 85px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 8px;
	}


	img {
		width: 100%;
		border-radius: 5px;
	}

	.title {
		width: 100px;
		text-align: center;
		font-size: 14px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.zsxmTab>>>.tab1 {
		transition: transform 0.3s ease;
	}


	.zsxmTab :hover>>>.tab1 {
		transform: scale(1.1);
	}

	.zsxmTab>>>.tab2 {
		transition: transform 0.3s ease;
	}


	.zsxmTab :hover>>>.tab2 {
		transform: scale(1.1);
	}

	.zsxmTab :hover {
		color: #3CA079;
	}

	.zsxmTab .el-image {
		height: 188px;
	}

	.zsxmTab {
		margin-top: 16px;
		color: #000000;
	}
</style>