<template>
	<div class="hxjsTab">
		<div class="siteBottom">
			<div class="fLeft" :style="{ backgroundImage: 'url(' + moduleDataSource.background + ')' }">
				<a href="javascript:;" @click="toList(moduleDataSource.title)">
					<img :src="moduleDataSource.img" alt="">
					<div>
						<p class="title">{{moduleDataSource.title}} <i class="el-icon-right"></i> </p>
						<p class="content" v-html="moduleDataSource.content"></p>
					</div>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	/* 滑县首页专题 */
	export default {
		name:'HuaxianSpecial',
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {}
			},
			moduleTable:'',
			moduleLinkConfig:{}
			
		},
		methods:{
			toList(item){

				if(item === '滑县招商服务') {
					this.$emit('navitage',this.moduleLinkConfig.more,this.moduleTable)
				} else if(item === '成本政策介绍') {
					localStorage.setItem('index',2)
					this.$router.push('/survey/list')
				}				
			}
		}
	}
</script>

<style scoped>
	.el-icon-right {
		font-size: 16px;
		font-weight: bold;
		color: #fff;
	}

	.hxjsTab .siteBottom a {
		display: flex;
		align-items: center;
		padding-left: 40px;
		height: 100%;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.65);
	}

	.hxjsTab .siteBottom a img {
		margin-right: 20px;
	}

	.hxjsTab .siteBottom a .title {
		font-family: PingFangSC-Semibold;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 28px;
		font-weight: 600;
		margin-bottom: 8px;
	}

	.hxjsTab .siteBottom .fLeft,
	.hxjsTab .siteBottom .fRight {
		height: 128px;
		transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-webkit-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
	}
	
	.hxjsTab .siteBottom .fLeft.on {
		transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-webkit-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
	}

	.hxjsTab .siteBottom .fLeft:hover {
		transform: translateY(-10px)
	}

	.hxjsTab .siteBottom .fRight:hover {
		transform: translateY(-10px)
	}

	.hxjsTab .siteBottom .fLeft {
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 100%;
	}
	.content /deep/ span{
		color:#fff!important;
	}
</style>
