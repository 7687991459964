<template>
	<div class="container">
		<div style="color:#56b55f;font-size: 20px;margin-bottom:10px ;">{{floorShowName}}</div>
		<div class="box">
			<div class="conter" v-for="(item, index) in moduleDataSource" :key="index">
				<div class="title">{{item.title}}</div>
				<div class="number">品牌：{{item.brand}}</div>
				<div class="price">价格：<span style="color: orange">{{item.price}}{{item.unit}}</span></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ListComponent',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => [],
			},
			floorShowName: "",
			moduleTable: "",
			moduleLinkConfig: {},
		},
		created() {
			console.log(this.moduleDataSource, '22')
		}
	}
</script>

<style scoped>
	.container {
		margin-top: 10px;
	}

	.box {
		width: 490px;
		/* height: 255px; */
		padding: 0 15px;
		border: 1px solid #cccccc;
		box-shadow: 0 4px 12px -7px rgba(0, 0, 0, .8);
		;
	}

	.conter {
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: solid 1px #f5f5f5;
	}

	.title {
		width: 40%;
	}

	.number {
		width: 30%;
	}

	.price {
		float: right;
	}
</style>