<template>
	<div class="content">
		<!-- 侧边栏 -->
		<div class="slideTab">
			<el-menu :default-openeds="['1']" >
				<el-submenu :index="item.content_sort" v-for="(item,index) in one_level_column" :key="index">
					<template slot="title">
						<div @click="oneLevelClick1(item,index)" :class="activeIndex1==index?'oneLevelClickactive':''">{{item.one_level_column}}</div>
					</template>
					<el-menu-item :class="{ active: activeIndex2 == index }" @click="oneLevelClick2(item,index)"
						v-for="(childrenItem, index) in item.children" :key="index">{{ childrenItem.two_level_column }}<i
							class="el-icon-d-arrow-right"></i> </el-menu-item> 
				</el-submenu>
			</el-menu>
		</div>

		<div class="slideContentTab">
			<!-- 导航栏 -->
			<div class="top">
				<div>
					<img style="vertical-align: baseline;margin-right:8px;margin-top:2px"
						src="@/assets/images/title_tips.png" alt="" />
				</div>
				<div class="ch">
					<template v-if="aa.children == ''">
						<div class="childrenList">{{one_title}}</div>
					</template>
					<template v-else>
						<template v-if="childrenList.children">
							<div class="childrenList" v-for="(item,index) in childrenList.children" :key="item.id" :class="activeIndex3==index?'active':''" @click="changeLevel3(item,index)">{{item.three_level_column}}</div>
						</template>
						<template v-else><div class="childrenList">{{one_title}}</div></template>
					</template>
				</div>
			</div>
			<div class="line">
				<p class="left"></p>
				<p class="right"></p>
			</div>
			<!-- 详情 -->
			<div class="detail">
				<div class="image" v-html="content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {formattingData} from '@/utils/util.js'
	import {getDetailById} from '@/api/api.js'
	export default {
		name: 'HuaxianGeneralSituation',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			moduleTable: ''
		},
		data() {
			return {
				tabList: [],
				one_level_column: [],
				aa: [],
				one_title:'',
				childrenList: [],
				activeIndex1: 0,
				activeIndex2: 0,
				activeIndex3: 0,
				id: '',
				content: '',
				loading:false,
			}
		},
		methods: {
				cc() {
				const getId = this.one_level_column[0].children[0].id
				this.one_title = this.one_level_column[0].children[0].two_level_column
				this.id = getId
				this.getDetail()
			},
			// 一级
			oneLevelClick1(item,index) {
				this.activeIndex1 = index
				this.aa = item
				const data = item.children
				if(data.length) {
					this.id = data[0].id
					this.one_title = data[0].two_level_column
				}	else {
					this.id = item.id
					this.one_title = item.one_level_column
				}
				this.getDetail()	
			},
			//  二级
			oneLevelClick2(item,index) {
				this.activeIndex2 = index
				const arry = item.children[index]
				console.log(arry)
				this.aa = arry
				this.one_title = arry.two_level_column
				this.childrenList = arry
				if(arry.children) {
					this.id = arry.children[0].id
				} else {
						this.id = arry.id
				}
				this.getDetail()
				this.activeIndex3 = 0
				this.activeIndex1 = 0 
			},
			//切换三级
			changeLevel3(item,index){
				this.id = item.id
				this.activeIndex3 = index
				this.getDetail()
			},
			
			getDetail(){
				//获取详情
				getDetailById('/merchants_county_overview/' + this.id).then(res => {
					if (res.success) {
		
						let records = res.result
						this.content = records.content_detail
						this.loading = true
					}	
				})
			},
			dataInit() {
				let data = this.tabList
				data.sort((a, b) => {
					return a.content_sort > b.content_sort ? 1 : -1;
				})
				data = formattingData(data, 'two_level_column')
				let leval1 = data[0]
				this.one_level_column = leval1.list
				var leval2 = data.slice(1);
				let arr = []
				leval2.forEach((item, index) => {
					item.list.forEach(listItem => {
						listItem.active = false
						if (listItem.three_level_column == null) {
							//二级栏目 并且没有下级
							arr.push(listItem)
						} else {
								if(arr[index] === undefined) {
										arr[index] = {
										two_level_column: item.two_level_column,
										children: [],
										active: false,
										one_level_column:listItem.one_level_column
									}
									// console.log(arr[index])
								}
							// 添加二级
							if (arr[index] === undefined) {
								arr[index]['children'].push(listItem)
							} else {
								arr[index]['children'].push(listItem)
							}
						}
					})
				})
				this.one_level_column.forEach(item=>{
					item.children = []
					arr.forEach(childrenItem=>{
						if(item.one_level_column == childrenItem.one_level_column){
							childrenItem.active = false
							item.children.push(childrenItem)
						}
					})
				})
				//this.level2 = arr
			},
			pathindex(){
				const index = Number(localStorage.getItem('index'))
			if(index) {
				this.activeIndex2 = index
				const title = this.one_level_column[0].children[2]
				this.childrenList = title
				this.id = title.children[0].id
				this.getDetail()
			}
			}
		},
		created() {
			window.onbeforeunload = function(event){
				localStorage.removeItem('index')
			};
			this.tabList = this.moduleDataSource
			this.dataInit()
			this.cc()
			this.pathindex()
		},

	}
</script>

<style scoped>
	.content {
		width: 1200px;
		margin: 0px auto;
		display: flex;
		justify-content: space-between;
	}

	.slideContentTab {
		width: 960px;
	}

	.top {
		display: flex;
	}

	.top .ch {
		width: 100%;
		display: flex;
	}

	.line {
		margin-top: 2px;
		margin-bottom: 31px;
	}

	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}

	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}

	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}

	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}

	/* 侧边栏 */
	.slideTab {
		width: 200px;
		height: 100%;
	}

	.slideTab ul>li .title {
		height: 44px;
		background: #3ca079;
		border-radius: 2px;
		font-family: PingFangSC-Semibold;
		font-size: 18px;
		color: #ffffff;
		font-weight: bold;
		text-align: center;
		line-height: 44px;
	}

	.slideTab ul>li>ul>li {
		line-height: 40px;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		text-align: left;
		padding: 0;
		padding-left: 20px !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.slideTab ul>li>ul>li:hover {
		background: #ebf5f1;
	}

	.slideTab ul>li>ul>li:hover .el-icon-d-arrow-right {
		display: block;
	}

	.el-icon-d-arrow-right {
		font-size: 16px;
		display: none;
	}

	::v-deep .el-menu {
		background-color: #fafafa;
		border-right: none;
	}

	.slideTab .active {
		background: #ebf5f1;
		color: #3ca079;
	}

	.slideTab .active .el-icon-d-arrow-right {
		display: block;
	}

	/deep/ .el-submenu__title {
		height: 44px;
		/* background: #3ca079; */
		background-color: #f1f1f1;
		font-size: 17px;
		color: #777879;
		text-align: center;
		line-height: 44px;
		text-align: left;
		position: relative;
	}
	.oneLevelClickactive {
		height: 100%;
		width: 200px;
		top:0;
		left: 0;
		position: absolute;
		background: #3ca079;
		padding: none;
		text-indent:20px;
		color: #fff;
	}

	.childrenList {
		font-size: 25px;
		padding: 5px 14px;
		cursor: pointer;
	}

	.ch .active {
		font-size: 25px;
		background-image: linear-gradient(#48ac85, #65c29e);
		border-radius: 5px;
		color: #ffffff;
		cursor: pointer;
	}

	.detail {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.el-submenu__title {
		color: #9c9c9c;
	}

	/deep/ .image img {
		max-height: 300px;
		/* height: auto; */
		max-width: 100%;
		/* margin: 0 auto; */
	}
</style>