<template>
	<div class="w1200">
		<div class="classificationTab" style="padding-top: 0;">
			<p class="title">贷款产品</p>
			<ul>
				<li v-for="(item,index) in classificationList" :key="index">
					<span class="name">{{ item.name }}</span>
					<span :class="item1.active?'type':''" @click="search(item1,index)" class="list"
						v-for="(item1,index1) in item.list" :key="index1">{{ item1.title }}</span>
				</li>
			</ul>
		</div>
		<div v-if="loansParticulars.length>0">
			<div class="dkcpTab">
				<ul>
					<li v-for="item in loansParticulars" :key="item.id" @click="openinfo(item)">
						<p class="name" v-if="item.bankLogo === true">
							<el-image :src="item.bankLogo"></el-image>
							<!-- {{ item.name }} -->
						</p>
						<p class="type">{{ item.projectName }}</p>
						<div class="info">
							<p class="vertical">
								<span class="num">{{ item.rate }}<em>%</em> </span>
								<span class="des">年利率</span>
							</p>
							<p class="line"></p>
							<p class="vertical">
								<span class="num">{{ item.quota }}<em>万</em> </span>
								<span class="des">最高贷款额度</span>
							</p>
							<p class="line"></p>
							<p class="vertical">
								<span class="num">{{ item.term }}<em>/个月</em> </span>
								<span class="des">贷款期限</span>
							</p>
						</div>
						<a href="javascript:;" class="btn">
							立即申请
							<i class="el-icon-caret-right"></i>
						</a>
						<p class="target" v-if="item.target === true">
							<span>适用对象：</span> {{ item.target }}
						</p>
					</li>
				</ul>
			</div>
			<div class="pagination">
				<el-pagination @current-change="handleCurrentChange" layout="prev, pager, next" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-empty v-else description="暂无记录"></el-empty>
	</div>
</template>
<script>
	import {
		getDataListByTableNames,
		getDictItemList
	} from '@/api/api.js'
	export default {
		name: 'HuaxianFinanceProjectList',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			modulePagination: {},
			moduleLinkConfig: {},
			moduleTable: '',
			floorId: {
				type: String,
				default: ''
			},
			moduleItem: {
				type: Object
			}
		},
		data() {
			return {
				page: 1,
				pageSize: this.modulePagination.pageSize,
				total: this.modulePagination.total,
				list: [],
				loansParticulars: [],
				params: {},
				classificationList: [{
						name: '贷款期限：',
						list: [{
							title: '全部',
							key: 'loan_period',
							value: '',
							active: true,
						}, {
							title: '6个月',
							key: 'loan_period',
							value: 6,
							active: false,
						}, {
							title: '12个月',
							key: 'loan_period',
							value: 12,
							active: false,
						}, {
							title: '24个月',
							key: 'loan_period',
							value: 24,
							active: false,
						}, {
							title: '36个月',
							key: 'loan_period',
							value: 36,
							active: false,
						}, {
							title: '60个月',
							key: 'loan_period',
							value: 60,
							active: false,
						}]
					},
					{
						name: '贷款类型：',
						list: [{
							title: '全部',
							key: 'loan_type',
							value: '',
							active: true,
						}, {
							title: '信用贷款',
							key: 'loan_type',
							value: '7',
							active: false,
						}, {
							title: '创业贷款',
							key: 'loan_type',
							value: '1',
							active: false,
						}, {
							title: '抵押贷款',
							key: 'loan_type',
							value: '2',
							active: false,
						}, {
							title: '质押贷款',
							key: 'loan_type',
							value: '3',
							active: false,
						}, {
							title: '保证贷款',
							key: 'loan_type',
							value: '4',
							active: false,
						}, {
							title: '小额贷款	',
							key: 'loan_type',
							value: '5',
							active: false,
						}, {
							title: '其他',
							key: 'loan_type',
							value: '6',
							active: false,
						}]
					},
				]
			};
		},
		watch: {
			moduleDataSource: {
				handler(newVal) {
					console.log(newVal)
					this.list = newVal
				},
				deep: true
			}
		},
		created() {
			this.moduleDataSource.forEach
			this.loansParticulars = this.moduleDataSource
			this.list = this.moduleDataSource
			this.getBank()
			//this.getLoanType()
		},
		methods: {
			getLoanType() {
				let params = {
					/* dictId: '1501851202204210004' */
				}
				getDictItemList(params).then(res => {
					if (res.success) {

					}
				})
			},
			getBank() {
				let params = {
					sys_org_code: this.BANKSYSORGCODE,
					sysOrgCode: this.BANKSYSORGCODE,
					show_status: '1'
				}
				getDataListByTableNames(this.moduleTable, params).then(res => {
					console.log(res)
					if (res.success) {
						let records = res.result.records
						let list = []
						this.classificationList.unshift({
							name: '所属银行：',
							list: [{
								title: '全部',
								key: 'bank_id',
								value: '',
								active: true,
							}]
						})
						records.forEach(item => {
							this.classificationList[0].list.push({
								title: item.product_bank,
								key: 'bank_id',
								value: item.bank_id,
								active: false,
							})
						})

						let repeatData = this.classificationList[0].list
						for (let i = 0; i < repeatData.length; i++) {
							for (let j = i + 1; j < repeatData.length; j++) {
								if (repeatData[i].title === repeatData[j].title) {
									repeatData.splice(j, 1)
									j--
								}
							}
						}
					}
				})
			},

			handleCurrentChange(val) {
				this.$emit('getPageList', val, this.moduleItem, this.floorId)
			},
			openinfo(item) {
				this.$emit('navitage', this.moduleLinkConfig.detail, this.moduleTable, item)
			},
			search(item, index) {
				this.classificationList[index].list.forEach(item => {
					item.active = false
				})
				item.active = true
				/* this.params[item.key] = item.value */
				this.moduleItem.componenConfig.attribute.queryParams.push({
					key: item.key,
					value: item.value
				})
				this.$emit('getPageList', 1, this.moduleItem, this.floorId)
			}
		},
	};
</script>

<style scoped>
	.pagination {
		text-align: center;
	}

	.classificationTab li {
		display: flex;
	}

	.list {
		background-color: #fff;
	}

	.dkcpTab {
		border-bottom: solid 1px #eee;
		padding-bottom: 20px;
	}

	.dkcpTab ul {
		display: flex;
		flex-wrap: wrap;
	}

	.dkcpTab ul li {
		width: 384px;
		/* height: 362px; */
		background: #FFFFFF;
		border: 1px solid #eee;
		border-radius: 4px;
		padding: 35px 21px 0;
		box-sizing: border-box;
		margin-bottom: 24px;
		margin-right: 24px;
	}

	.dkcpTab ul li:nth-child(3n+3) {
		margin-right: 0;
	}

	.dkcpTab ul li .name {
		font-size: 14px;
		color: #666666;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dkcpTab ul li .type {
		font-size: 24px;
		color: #333333;
		font-weight: bold;
		margin: 12px 0 17px;
		text-align: center;
	}

	.dkcpTab ul li .info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: center;
	}

	.dkcpTab ul li .info .vertical {
		display: flex;
		flex-direction: column;
		width: 112px;
	}

	.dkcpTab ul li .name .el-image {
		/* width:22px;
        margin-right:10px; */
		/* height: 20px; */
		height: 60px;
	}

	.dkcpTab ul li .info .num {
		font-size: 28px;
		color: #EE6B28;
	}

	.dkcpTab ul li .info .num em {
		font-style: normal;
		font-size: 14px;
	}

	.dkcpTab ul li .info .txt {
		font-size: 14px;
		color: #333333;
	}

	.dkcpTab ul li .line {
		width: 1px;
		height: 40px;
		background-color: #eee;
	}

	.dkcpTab ul li .info .vertical .des {
		font-size: 14px;
	}

	.dkcpTab ul li a.btn {
		width: 160px;
		height: 40px;
		background: #FFFFFF;
		border: 1px solid #3CA079;
		border-radius: 20px;
		display: block;
		text-align: center;
		line-height: 40px;
		font-size: 14px;
		color: #3CA079;
		font-weight: 500;
		margin: 23px auto;
	}

	.dkcpTab ul li .target {
		border-top: solid 1px #eee;
		background: #FAFAFA;
		font-size: 14px;
		color: #333333;
		text-align: center;
		margin-left: -21px;
		margin-right: -21px;
		line-height: 48px;
	}

	.dkcpTab ul li .target span {
		color: #999;
	}

	.dkcpTab ul li:hover {
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.10);
	}

	.dkcpTab ul li:hover a.btn {
		background: #3CA079;
		;
		color: #fff;
	}

	/deep/ .el-pagination {
		margin-bottom: 0;
	}

	.list:hover {
		cursor: pointer;
	}
</style>