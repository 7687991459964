import {
	constantRoutes
} from '@/router'
import {
	getSysUiconfigPage
} from '@/api/api.js'
import Vue from 'vue' 
/* 
 使用 meta.role 来确定用户是否具有权限
 */
function hasPermission(roles, route) {
	if (route.meta && route.meta.roles) {
		return roles.some(role => route.meta.roles.includes(role))
	}
}
/* 
 把后台返回菜单组装成routes要求的格式
 */
export function getAsyncRoutes(routes) {
	const res = []
	routes.forEach((item,index)=>{
		const newItem = {}
		newItem.path = item.pageLink
		newItem.meta = {
			title:item.pageName,
			roles:['admin']
		}
		newItem.component = ()=> import(`@/components/${item.pageModule}`)
		newItem.hidden = item.pageIsNavigation
		res.push(newItem)
	})
	return res
}
/* export function getAsyncRoutes(routes) {
	const res = []
	const keys = ['path', 'name', 'children', 'redirect', 'alwaysShow', 'meta', 'hidden']
	routes.forEach(item => {
		const newItem = {}
		if (item.component) {
			if (item.component === 'layout/Layout') {
				newItem.component = Layout
			} else {
				newItem.component = () => import(`@/${item.component}`)
			}
		}
		for (const key in item) {
			if (keys.includes(key)) {
				newItem[key] = item[key]
			}
		}
		if (newItem.children && newItem.children.length) {
			newItem.children = getAsyncRoutes(item.children)
		}
		res.push(newItem)
	})
	return res
} */




/* 
 按递归筛选异步路由表
 */
export function filterAsyncRoutes(routes, roles) {
	const res = []
	routes.forEach(route => {
		const tmp = {
			...route
		}
		if (hasPermission(roles, tmp)) {
			if (tmp.children) {
				tmp.children = filterAsyncRoutes(tmp.children, roles)
			}
			res.push(tmp)
		}
	})
	return res
}

const state = {
	routes: [],
	addRoutes: [],
}

const mutations = {
	SET_ROUTES: (state, routes) => {
		state.addRoutes = routes
		state.routes = constantRoutes.concat(routes)
	}
}

const actions = {
	generateRoutes({
		commit
	}, roles) {
		return new Promise(async resolve => {
			let accessedRoutes
			let params = {
				pageType:1,
				tenantId:Vue.prototype.TENANTID,
				pageSize:50
			}
			console.log(params)
			const routes = await getSysUiconfigPage(params).then(res => {
				if (res.success) {
					let routes = {}
					routes.data = res.result.records
					return routes
				}
			}) //获取到后台路由
			const asyncRoutes = getAsyncRoutes(routes.data) // 对路由格式进行处理
			if (roles.includes('admin')) {
				accessedRoutes = asyncRoutes || []
			} else { // 这里是有做权限过滤的,如果不需要就不用
				//accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
			}
			commit('SET_ROUTES', accessedRoutes)
			resolve(accessedRoutes)
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
