<template>
	<div class="content">
		<div class="fLeft">
			<p class="title" v-if="moduleDataSource.title">{{ moduleDataSource.title }}</p>
			<div v-html="removeHtmlStyle(moduleDataSource.content)" class="txt"></div>
			<div class="wrap-ewm">
				<img :src="getEwmImg(moduleDataSource.img)">
				<div class="wrap-ewm-info fontSize16 c666">
					<div>扫一扫</div>
					<div>马上申请</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getFileAccessHttpUrl
	} from '@/utils/util.js'
	export default{
		name:'HuaxianFinanceProjectDetail',
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {}
			},
		},
		methods:{
			getEwmImg(url){
				return getFileAccessHttpUrl(url)
			},
		}
	}
</script>

<style scoped>
	.content {
		overflow: hidden;
	}
	
	.title {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		font-weight: 500;
		margin-bottom: 20px;
		text-align: center;
	}
	
	.date {
		font-size: 18px;
		color: #999;
		text-align: center;
		margin-bottom: 30px;
	}
	
	.date span {
		margin: 0 20px;
	}
	
	.txt {
		font-size: 16px;
		color: #333;
		margin-top: 30px;
	}
	
	.txt /deep/ p {
		line-height: 2;
		font-size: 16px;
		color: #333;
	}
	.txt /deep/ p span{
		line-height: 2!important;
		font-size: 16px!important;
		color: #333!important;
	}
	.txt /deep/ .rich-img{
		max-width: 100%;
		margin:0 auto;
		height: auto;
	}
	.wrap-ewm {
		width: 120px;
		position: fixed;
		right: 100px;
		bottom: 300px;
	}
	
	.wrap-ewm img {
		width: 120px;
		height: 120px;
		margin-bottom: 10px;
	}
	
	.wrap-ewm-info {
		display: flex;
		justify-content: space-between;
	}
</style>