<template>
	<div class="toDetailTab">
		<img :src="moduleDataSource.img" alt="">
		<div class="title">{{moduleDataSource.title}}</div>
		<div class="content" v-html="moduleDataSource.content"></div>
		<a href="javascript:;" @click="toMore" class="btn"><span>查看详情</span> <i class="el-icon-caret-right"></i> </a>
	</div>
</template>

<script>
	export default{
		name:'HuaxianBannerLink',
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {}
			},
			moduleLinkConfig:{},
			moduleTable:''
		},
		methods: {
			toMore() {
				this.$emit('navitage', this.moduleLinkConfig.more, this.moduleTable)
			}
		}
	}
</script>

<style scoped>
	.toDetailTab {
		position: relative;
	}
	.toDetailTab img{
		width:100%;
	}
	.toDetailTab .btn {
		position: absolute;
		width: 122px;
		height: 38px;
		border-radius: 20px;
		background: #fff;
		left: 41px;
		bottom: 34px;
		font-size: 14px;
		color: #3CA079;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.title{
		position: absolute;
		color: #fff;
		left:40px;
		top:40px;
		font-size: 36px;
	}
	.content{
		position: absolute;
		color: #fff;
		left:40px;
		top:95px;
	}
</style>