<template>
	<div class="xyhtj">
	   <div class="container">
	   	<div class="top">
	   		<div>
	   			<img style="vertical-align: baseline;" src="@/assets/images/title_tips.png" alt="">
	   			<span class="ch">{{floorShowName}}</span>
	   		</div>
	   		<!-- <div v-if="titleInfo[2]">
	              <router-link :to="{name:titleInfo[3]}">
	                  <span class="more" >更多</span>
	                  <i class="el-icon-arrow-right"></i>
	              </router-link>
	          </div> -->
	   	</div>
	   	<div class="line">
	   		<p class="left"></p>
	   		<p class="right"></p>
	   	</div>
	   </div>
	    <div class="echart2">
	         <xyhtj-echarts :data="xyhtjData"></xyhtj-echarts>
	    </div>
	</div>
</template>

<script>
	import xyhtjEcharts from "@/components/xyhtjEcharts"
	import {
		formattingData
	} from '@/utils/util.js'
	export default {
		name: 'HuaxianBrokenLine',
		components:{
			xyhtjEcharts
		},
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			floorShowName: '',
		},
		data() {
			return {
				xyhtjData:{
				    line2:[],
				    line1:[],
				    name:[]
				},
			}
		},
		created(){
			let arr = this.moduleDataSource
			arr.sort((a, b) => {
				return a.yName > b.yName ? 1 : -1;
			})
			arr = formattingData(arr,'xName')
			arr.forEach(item=>{
				this.xyhtjData.name.push(item.xName)
				item.list.forEach(listItem=>{
					this.xyhtjData.line1.push(listItem.line2)
					this.xyhtjData.line2.push(listItem.line1)
				})
			})
		}
	}
</script>

<style scoped>
	.echart2{
	    width:100%;
	    height:380px;
	    margin-top:12px;
	}
	/* 标题 start */
	.container {
		margin: 0 auto;
		height: 54px;
	}
	
	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}
	
	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}
	
	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}
	
	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3CA079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}
	
	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}
	
	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3CA079;
		margin-left: 4px;
	}
	
	.line {
		margin-top: 16px;
	}
	
	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}
	
	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}
	
	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}
	
	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}
	
	/* 标题 end */
</style>