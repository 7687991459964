<template>
	<div class="siteTop">
		<div class="fLeft">
			<div class="video">
				<vueVideoPlayer :src="moduleDataSource.src" :cover_url="moduleDataSource.img" />
			</div>
			<p class="txt">
				{{moduleDataSource.videoTitle}}
			</p>
		</div>
		<div class="fRight">
			<div class="container">
				<div class="top">
					<div>
						<img src="@/assets/images/title_tips.png" alt="">
						<span class="ch">{{floorShowName}}</span>
					</div>
				</div>
				<div class="line">
					<p class="left"></p>
					<p class="right"></p>
				</div>
			</div>
			<p class="info fontOverHidden5" v-html="moduleDataSource.content">
			</p>
		</div>
	</div>
</template>

<script>
	/* 滑县介绍模块 */
	import vueVideoPlayer from '@/components/base/MyVideoPlayer'
	export default {
		name: 'HuaxianInfo',
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {}
			},
			floorShowName: ''
		},
		components: {
			vueVideoPlayer
		},
		created() {
			console.log(this.moduleDataSource,'执行了')
		}
	}
</script>

<style scoped>
	p.info {
		margin-top: 28px;
		color: #555555;
		line-height: 28px;
	}

	.siteTop {
		background: #FFFFFF;
		box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
		border-radius: 4px;
	}

	.siteTop .fLeft .txt {
		position: absolute;
		top: -8px;
		width: 232px;
		height: 32px;
		background: url("@/assets/images/index_video_title_bg.png") no-repeat;
		background-size: 100% 100%;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 32px;
		padding-left: 12px;
	}

	.siteTop .fLeft .video {
		width: 480px;
		height: 270px;
		background: url("@/assets/images/index_video_img.jpg") no-repeat;
		background-size: 100% 100%;
		position: relative;
	}

	.siteTop .fRight {
		width: 56.333%;
		padding-top: 32px;
	}

	.container {
		width: 100%;
		height: 54px;
	}

	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}

	.container .top img {
		vertical-align: baseline;
	}

	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}

	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3CA079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}

	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}

	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3CA079;
		margin-left: 4px;
	}

	.line {
		margin-top: 16px;
	}

	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}

	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}

	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}

	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}

	.info /deep/ p,
	.info /deep/ span {
		font-size: 16px !important;
		color: #555 !important;
		line-height: 28px !important;
		
		font-family: PingFangSC-Regular, Avenir, Helvetica, Arial, sans-serif!important;
	}
</style>
