<template>
	<div class="grid" :style="{ 'grid-template-columns': `repeat(${columns},1fr)` }">
		<div class="list" v-for="item in moduleDataSource" :key="item.id">
			<div class="video">
				<vueVideoPlayer :src="item.src" :cover_url="item.img" />
			</div>
			<p class="txt">{{item.videoTitle}}</p>
		</div>
	</div>
</template>

<script>
	import vueVideoPlayer from '@/components/base/MyVideoPlayer'
	export default {
		name: 'VideoPlayback',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => [],
			}
		},
		data() {
			return {
				len: 14,
				columns: 4, // 这个就是你需要几列这里就填写几
			}
		},
		components: {
			vueVideoPlayer
		}
	}
</script>

<style scoped>
	.grid {
		margin-top: 20px;
		display: grid;
		grid-gap: 20px 25px;
		grid-template-columns: repeat(3, 30%);
		justify-content: space-between;
	}

	.list {
		position: relative;
		width: 280px;
		height: 159px;
		background-color: #e8f0cc;
	}

	.video {
		width: 280px;
		height: 159px;
		background: url("@/assets/images/index_video_img.jpg") no-repeat;
		background-size: 100% 100%;
		position: relative;
	}

	/deep/.vjs-custom-skin>.video-js .vjs-big-play-button {
		left: 57%;
	}

	.txt {
		position: absolute;
		top: -8px;
		width: 210px;
		height: 30px;
		background: url("@/assets/images/index_video_title_bg.png") no-repeat;
		background-size: 100% 100%;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 30px;
		padding-left: 12px;
	}

	/deep/.vjs-poster {
		background-color: #ffffff;
	}
</style>