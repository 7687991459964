<template>
	<div class="content">
		<div>
			<p class="title" v-if="moduleDataSource.title">{{ moduleDataSource.title }}</p>
			<p class="date">
				<span v-if="moduleDataSource.date">{{ moduleDataSource.date }}</span>
				<span v-if=" moduleDataSource.source">文章来源：{{ moduleDataSource.source }}</span>
			</p>
			<div v-html="removeHtmlStyle(moduleDataSource.content)" class="txt"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HuaxianNewDetail',
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {}
			},
		},
	}
</script>

<style scoped>
	.content {
		overflow: hidden;
		min-height:60vh;
	}

	.title {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		font-weight: 500;
		margin-bottom: 20px;
		text-align: center;
	}

	.date {
		font-size: 18px;
		color: #999;
		text-align: center;
		margin-bottom: 30px;
	}

	.date span {
		margin: 0 20px;
	}

	.txt {
		font-size: 16px;
		color: #333;
		margin-top: 30px;
	}

	.txt /deep/ p {
		line-height: 2;
		font-size: 16px;
		color: #333;
	}
	.txt /deep/ p span{
		line-height: 2!important;
		font-size: 16px!important;
		color: #333!important;
	}
	.txt /deep/ .rich-img{
		max-width: 100%;
		margin:0 auto;
		height: auto;
	}
</style>
