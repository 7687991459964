<template>
	<div>
		<div class="content">
			<div class="slideTab fLeft">
				<el-menu :default-active="defualtActive" active-text-color="#3CA079" class="el-menu-vertical-demo"
					:default-openeds="opens">
					<el-submenu :index="item.content_sort" v-for="item in menuList" :key="item.content_sort">
						<template slot="title">
							<span>{{ item.one_level_column }}</span>
						</template>

						<el-menu-item @click="menuSelect(item1)" :index="item1.content_sort" v-for="item1 in item.list"
							:key="item1.content_sort">{{ item1.two_level_column }} <i class="el-icon-d-arrow-right"></i> </el-menu-item>

					</el-submenu>
				</el-menu>
			</div>
			<div class="slideContentTab fRight">
				<div class="container">
				    <div class="top">
				        <div>
				            <img style="vertical-align: baseline;" src="@/assets/images/title_tips.png" alt="">
				            <span class="ch">{{currentTwoLevelTitel}}</span>
				        </div>
				    </div>
				    <div class="line">
				        <p class="left"></p>
				        <p class="right"></p>
				    </div>
				</div>
				<div class="content-wrap" style="padding-top:24px;" v-html="content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getDetailById
	} from '@/api/api.js'
	import {
		formattingData
	} from '@/utils/util.js'
	export default {
		name: 'HuaxianMenuDetail',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			moduleTable:''
		},
		data() {
			return {
				opens: [],
				menuList: [],
				content:'',
				currentTwoLevelTitel:"",
				defualtActive:''
			};
		},

		mounted() {
		//	this.zjhxContent = this.menuList[0].list[0].info;
			let arr  = this.moduleDataSource
			arr.sort((a, b) => {
				return a.content_sort > b.content_sort ? 1 : -1;
			})
			arr = formattingData(arr,'one_level_column')
			arr.forEach((item,index)=>{
				item['content_sort'] = index+1 +''
				this.opens.push(item['content_sort'])
			})
			this.menuList = arr
			this.defualtActive = this.menuList[0].list[0].content_sort
			this.getDetail(this.menuList[0].list[0].id)
		},
		methods: {
			menuSelect(value) {
				this.getDetail(value.id)
			},
			getDetail(id){
				getDetailById('/'+this.moduleTable+'/'+id).then(res=>{
					if(res.success){
						this.content = res.result.content_detail
						this.currentTwoLevelTitel = res.result.two_level_column
					}
				})
			}
		},
	};
</script>

<style scoped >
	.content {
		min-width: 1200px;
		margin: 0px auto ;
		display: flex;
		justify-content: space-between;
	}

	.slideTab {
		width: 200px;
	}

	.slideTab ul>li .title {
		height: 44px;
		background: #3CA079;
		border-radius: 2px;
		font-family: PingFangSC-Semibold;
		font-size: 18px;
		color: #FFFFFF;
		font-weight: bold;
		text-align: center;
		line-height: 44px;
	}

	.slideTab ul>li>ul>li {
		line-height: 40px;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #666666;
		text-align: left;
		padding: 0;
		padding-left: 20px !important;
		display: flex;
		justify-content: space-between;
		align-items: center;

	}

	.slideTab ul>li>ul>li:hover {
		background: #EBF5F1;
	}

	.slideTab ul>li>ul>li:hover .el-icon-d-arrow-right {
		display: block;
	}

	.el-icon-d-arrow-right {
		font-size: 16px;
		display: none;
	}
	/deep/ .el-menu{
		background-color:#fafafa;
		border-right: none;
	}
	.el-submenu .el-menu-item {
		height: 40px;
		width: 200px;
		padding-left: 20px;

	}

	.slideTab ul>li>ul>li.is-active {
		background: #EBF5F1;
	}

	.slideTab ul>li>ul>li.is-active .el-icon-d-arrow-right {
		display: block;
	}

	.slideContentTab {
		width: 960px;
	}

	/deep/.el-submenu .el-submenu__title {
		height: 44px;
		background: #3CA079;
		border-radius: 2px;
		font-family: PingFangSC-Semibold;
		font-size: 18px;
		color: #FFFFFF;
		font-weight: bold;
		text-align: center;
		line-height: 44px;
		text-align: left;
	}
	/* 标题 start */
	.container{
	    width:100%;
	    height:54px;
	}
	.container .top{
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    height:36px;
	}
	.container .top a{
	    display: flex;
	    align-items: center;
	    height:100%;
	}
	.container .top .ch{
	    font-family: PingFangSC-Medium;
	    font-size: 28px;
	    color: #181818;
	    line-height: 36px;
	    font-weight: 500;
	    margin-left:10px;
	}
	.container .top .en{
	    font-family: PingFangSC-Semibold;
	    font-size: 12px;
	    color: #3CA079;
	    line-height: 16px;
	    font-weight: 600;
	    margin-left:10px;
	    text-transform: uppercase;
	}
	.container .top .more{
	    font-family: PingFangSC-Regular;
	    font-size: 14px;
	    color: #666666;
	}
	.container .top .el-icon-arrow-right{
	    font-size: 16px;
	    color: #3CA079;
	    margin-left:4px;
	}
	.line{
	    margin-top:16px;
	}
	.line .left{
	    width:200px;
	    height:2px;
	    background-color: #3ca079;
	    float: left;
	}
	.line .right{
	    width:calc(100% - 226px);
	    height:1px;
	    background-color: #cee7dd;
	    float:right;
	    position: relative;
	}
	.line .right::before{
	    width:20px;
	    height:2px;
	    background-color: #3ca079;
	    position: absolute;
	    content:"";
	    left:0;
	    top:0;
	}
	.line .right::after{
	    width:20px;
	    height:2px;
	    background-color: #3ca079;
	    position: absolute;
	    content:"";
	    right:0;
	    top:0;
	}
	/* 标题 end */
	.content-wrap /deep/ img{
		max-width: 100%;
	}
</style>
