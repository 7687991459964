<template>
	<div></div>
</template>

<script>
	export default {
		name: 'Layout',
	}
</script>

<style>
</style>
