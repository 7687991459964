<template>
	<div class="hzhbTab">
		<div class="container">
			<div class="top">
				<div>
					<img style="vertical-align: baseline;" src="@/assets/images/title_tips.png" alt="">
					<span class="ch">{{floorShowName}}</span>
				</div>
				<!-- <div v-if="titleInfo[2]">
		            <router-link :to="{name:titleInfo[3]}">
		                <span class="more" >更多</span>
		                <i class="el-icon-arrow-right"></i>
		            </router-link>
		        </div> -->
			</div>
			<div class="line">
				<p class="left"></p>
				<p class="right"></p>
			</div>
		</div>
		<div class="content">
			<a href="javascript:;" v-for="(item,index) in moduleDataSource" :key="index" class="wrap-img">
				<img :src="getImgSrc(item.img)" alt="">
			</a>
		</div>
	</div>
</template>

<script>
	import {
		getFileAccessHttpUrl
	} from '@/utils/util.js'
	export default {
		name: 'HuaxianCooperationList',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			floorShowName: ''
		},
		methods: {
			getImgSrc(url) {
				return getFileAccessHttpUrl(url)
			}
		}
	}
</script>

<style scoped>
	.hzhbTab{
	    padding-bottom:60px;
	    background: url('@/assets/images/jrfw_p3_bg.png') no-repeat;
	    background-size: 100% 100%;
	}
	.hzhbTab .content{
		width:62%;
		min-width: 1200px;
	    margin:32px auto 0;
	    display: flex;
	    flex-wrap: wrap;
	}
	.wrap-img{
		display: flex;
		align-items: center;
		justify-content: center;
		width:285px;
		height: 80px;
		background:#fff;
	}
	.hzhbTab .content img{
	   /*  width:180px;*/
	    height:72px; 
	}
	.hzhbTab .content a{
	    margin-bottom: 20px;
		margin-right:20px;
	}
	.hzhbTab .content a:nth-child(4n+4) {
	    padding-right: 0;
		margin-right:0px;
	}
	/* 标题 start */
	.container {
		width: 62%;
		margin:0 auto;
		height: 54px;
		min-width:1200px;
	}
	
	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}
	
	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}
	
	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}
	
	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3CA079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}
	
	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}
	
	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3CA079;
		margin-left: 4px;
	}
	
	.line {
		margin-top: 16px;
	}
	
	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}
	
	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}
	
	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}
	
	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}
	
	/* 标题 end */
</style>
