<template>
	<div class="zsztTab">
		<div class="w1200">
			<div class="container">
				<div class="top">
					<div>
						<img style="vertical-align: baseline;" src="@/assets/images/title_tips.png" alt="">
						<span class="ch">{{floorShowName}}</span>
					</div>
				</div>
				<div class="line">
					<p class="left"></p>
					<p class="right"></p>
				</div>
			</div>
			<div class="content">
				<div class="menu">
					<el-menu default-active="1" active-text-color="#3CA079" class="el-menu-vertical-demo">
						<el-menu-item :index="item.content_sort+''" v-if="item.show_status==1" v-for="item in menuList" :key="item.content_sort"
							@click="handSelect(item)">
							<p> 
								<el-image :src="item.src"></el-image>
							</p>
							<span>{{ item.title }}</span>
						</el-menu-item>
					</el-menu>
				</div>
				<div class="menuContent">
					<div class="wrap">
						<div class="fLeft">
							<P class="title" v-if="moduleName">{{ moduleName}}</P>
							<p class="txt" v-if="content" v-html="content"></p>
						</div>
						<div class="fRight" v-if="ImgData.length>0 && imgIndex==0" v-for="(imgItem,imgIndex) in ImgData">
							<el-image :src="imgItem"></el-image>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getDetailById} from '@/api/api.js'
	export default {
		name: 'HuaxianAttractCarrier',
		props: {
			moduleDataSource: {
				type: Array,
				default: () => []
			},
			floorShowName: '',
			moduleTable: '',
		},
		data() {
			return {
				menuList: [{
						src: require("@/assets/images/hxzs_icon1.png"),
					},
					{
						src: require("@/assets/images/hxzs_icon2.png"),
					},
					{
						src: require("@/assets/images/hxzs_icon3.png"),
					}
				],
				content: {},
				moduleName:'',
				ImgData:[]
			}
		},
		methods: {
			handSelect(value) {
				this.getDetail(value.id)
			},
			getDetail(id) {
				getDetailById('/' + this.moduleTable + '/' + id).then(res => {
					if (res.success) {
						this.content = res.result.content_detail
						this.moduleName = res.result.module_name
						let that = this
						this.ImgData = []
						/* var data = [];
						var str = []; */
						this.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, function (match, capture) {
						   that.ImgData.push(capture);
						   //str.push(match);
						});
						/* console.log(data)
						console.log(str) */
						this.content = this.content.split('<img')[0]
					}
				})
			}
		},
		created() {
			let arr = this.moduleDataSource
			arr.sort((a, b) => {
				return a.content_sort > b.content_sort ? 1 : -1;
			})
			arr.forEach((item, index) => {
				this.menuList[index] = Object.assign({}, this.menuList[index], item)
			})
			this.getDetail(this.menuList[0].id)
		}
	}
</script>
<style scoped>
	.zsxmTab ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 24px;
	}

	.zsxmTab li {
		width: 282px;
		margin-bottom: 24px;
	}

	.zsxmTab li:hover p {
		color: #3CA079;
	}

	.zsxmTab li p {
		margin-top: 16px;
		color: #000000;
	}

	.zsxmTab {
		padding-top: 40px;
		margin-bottom: 36px;
		position: relative;
	}

	.zxTab .en {
		font-family: AlibabaPuHuiTi-Medium;
		font-size: 48px;
		color: #EEEEEE;
		text-align: center;
		line-height: 48px;
		font-weight: 500;
		position: absolute;
		top: 16%;
		width: 100%;
		text-align: center;
		opacity: 0.07;
		z-index: 1;
	}

	.zxTab {
		height: 240px;
		background: url("@/assets/images/hxzs_zx_bg.png") no-repeat;
		background-size: 100% 100%;
		position: relative;
		margin-bottom: 60px;
		overflow: hidden;

	}

	.zxTab .title {
		font-family: AlibabaPuHuiTi-Medium;
		font-size: 32px;
		color: #FFFFFF;
		margin-bottom: 32px;
		font-weight: 500;
		text-align: center;
		padding-top: 58px;
	}

	.zxTab .search {
		width: 640px;
		margin: 0 auto;
		position: relative;
		z-index: 2;
	}

	.el-select .el-input {
		width: 520px;
	}

	/deep/ .el-input-group__append {
		background-color: transparent;
		background-image: linear-gradient(270deg, #6FCEB0 0%, #3CA079 100%);
		border-radius: 4px;
		color: #fff;
		border-width: 0;

	}

	/deep/ .el-input__inner {
		line-height: 52px;
		height: 52px;
		font-size: 16px;
		color: #AAAAAA;
	}

	.zsztTab {
	}

	.zsztTab .content {
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
	}

	.zsztTab .menu {
		width: 240px;
		border-right: solid 1px #eee;
		box-sizing: border-box;
	}

	.zsztTab /deep/ .el-menu-item {
		display: flex;
		align-items: center;
		height: 64px;
		line-height: 64px;
	}

	.zsztTab .el-menu-item /deep/ p {
		height: 28px;
		width: 28px;
		margin-right: 12px;
	}

	.zsztTab /deep/ .el-menu-item.is-active {
		width: 240px;
		background: rgba(60, 160, 121, 0.08);
		box-shadow: inset -3px 0 0 0 #3CA079;
		border-right: solid 1px #3CA079;
	}

	.zsztTab /deep/ .el-menu-item:hover {
		width: 240px;
		background: rgba(60, 160, 121, 0.08);
		box-shadow: inset -3px 0 0 0 #3CA079;
		border-right: solid 1px #3CA079;
	}

	.menuContent {
		width: 920px;
	}

	/* 标题 start */
	.container {
		margin: 0 auto;
		height: 54px;
	}

	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}

	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}

	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3CA079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}

	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}

	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3CA079;
		margin-left: 4px;
	}

	.line {
		margin-top: 16px;
	}

	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}

	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}

	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}

	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}

	/* 标题 end */

	.menuContent .wrap {
		width: 100%;
		overflow: hidden;
	}

	.menuContent .fLeft {
		width: 568px;
	}

	.menuContent .title {
		font-family: PingFangSC-Medium;
		font-size: 32px;
		color: #181818;
		font-weight: 500;
		margin-bottom: 24px;
	}

	.menuContent .txt {
		font-size: 16px;
		color: #555555;
	}
	.menuContent .txt /deep/ img{
		display: none!important;
	}
	.menuContent .el-image {
		width: 320px
	}
</style>
