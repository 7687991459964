<template>

	<div class="container">
		<el-dialog :visible="dialogVisible" class="dialog" @close="close" width="50%" style=" top:50px">
			<div class="box">
				<div class="ScanCode_img">
					<img :src="moduleDataSource.ScanCode" alt="" style="height: 100%;" />
				</div>
				<div style="margin-top: 10px; font-size: 16px;" v-html="moduleDataSource.title"></div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'PopFrame',
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {}
			},
			moduleLinkConfig: {},
			moduleTable: "",
		},
		created() {
			console.log(this.moduleLinkConfig, '111')
		},
		data() {
			return {
				dialogVisible: true,
			}
		},
		methods: {
			close() {
				this.dialogVisible = false
				this.$emit('navitage', this.moduleLinkConfig.close, this.moduleTable)
			},
		},

	}
</script>

<style lang="less" scoped>
	.container {
		width: 1200px;
		height: 1200px;
		top: 0;
		left: 0;
		background-color: #ffffff;
		position: relative;
	}

	.box {
		width: 300px;
		height: 300px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.ScanCode_img {
		width: 200px;
		height: 200px;

	}
</style>