<template>
	<div class="yldjTab">
		<div class="container">
			<div class="top">
				<div>
					<img style="vertical-align: baseline;" src="@/assets/images/title_tips.png" alt="">
					<span class="ch">{{floorShowName}}</span>
				</div>
				<div v-if="moduleLinkConfig.more">
					<a href="javascript:void(0);" @click="toList">
						<span class="more">{{moduleLinkConfig.more.buttonName}}</span>
						<i class="el-icon-arrow-right"></i>
					</a>
				</div>
			</div>
			<div class="line">
				<p class="left"></p>
				<p class="right"></p>
			</div>
		</div>
		<div class="content">
			<div class="fLeft fontOverHidden9" v-html="moduleDataSource.content"></div>
			<div class="fRight" style="width:480px;height: 270px;" v-if="moduleDataSource.img">
				 <el-image
				      style="width:480px;height: 270px;"
				      :src="moduleDataSource.img"
				      :fit="contain"></el-image>
			</div>
		</div>
	</div>
</template>

<script>
	/* 滑县首页党建引领信用村*/
	export default {
		name: 'HuaxianVillageInfoDetail',
		props: {
			moduleDataSource: {
				type: Object,
				default: () => {}
			},
			floorShowName: '',
			moduleTable: '',
			moduleLinkConfig: {}
		},
		methods: {
			toList() {
				this.$emit('navitage', this.moduleLinkConfig.more, this.moduleTable)
			}
		}
	}
</script>

<style scoped>
	.yldjTab {
		overflow: hidden;
	}

	.yldjTab .content {
		margin-top: 32px;
	}

	.yldjTab .fLeft {
		width: 56.666%;
		padding-top: 20px;
		font-size: 16px;
		color: #555555;
		line-height: 28px;
	}

	/* 标题 start */
	.container {
		width: 100%;
		height: 54px;
	}

	.container .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 36px;
	}

	.container .top a {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.container .top .ch {
		font-family: PingFangSC-Medium;
		font-size: 28px;
		color: #181818;
		line-height: 36px;
		font-weight: 500;
		margin-left: 10px;
	}

	.container .top .en {
		font-family: PingFangSC-Semibold;
		font-size: 12px;
		color: #3CA079;
		line-height: 16px;
		font-weight: 600;
		margin-left: 10px;
		text-transform: uppercase;
	}

	.container .top .more {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
	}

	.container .top .el-icon-arrow-right {
		font-size: 16px;
		color: #3CA079;
		margin-left: 4px;
	}

	.line {
		margin-top: 16px;
	}

	.line .left {
		width: 200px;
		height: 2px;
		background-color: #3ca079;
		float: left;
	}

	.line .right {
		width: calc(100% - 226px);
		height: 1px;
		background-color: #cee7dd;
		float: right;
		position: relative;
	}

	.line .right::before {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		left: 0;
		top: 0;
	}

	.line .right::after {
		width: 20px;
		height: 2px;
		background-color: #3ca079;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
	}

	/* 标题 end */
</style>
