import router from './router'
import store from './store'
import {
	Message
} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {
	getToken
} from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({
	showSpinner: false
})

const whiteList = ['/login', '/auth-redirect']

router.beforeEach(async (to, from, next) => {
	//开始加载滚动条
	NProgress.start()

	//设置页面标题
	document.title = getPageTitle(to.meta.title)

	//确定用户是否已登录   现在还没有需求给true
	const hasToken = true || getToken()
	if (hasToken) {
		if (to.path === '/login') {
			//如果已登录，重定向首页
			next({
				path: '/'
			})
			NProgress.done()
		} else {
			//确定用户是否已通过getInfo获得其权限角色
			const hasRoles = store.getters.roles && store.getters.roles.length > 0
			if (hasRoles) {
				next()
			} else {
				try {
					//获取用户信息
					//roles 必须是对象数组，例如 ['admin'] 或者 ['developer','editor']
					/* const {
						roles
					} = await store.dispatch('user/getInfo') */
					//基于角色生成可访问路由
					//const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
					store.commit('user/SET_ROLES',['admin'])
					const accessRoutes = await store.dispatch('permission/generateRoutes', ['admin'])
					//动态添加可访问路由
					accessRoutes.forEach(item=>{
						router.addRoute(item)
					})
					// hack方法以确保addRoutes完整
					// 设置replace:true，这样导航就不会留下历史记录
					next({...to, replace: true})
				} catch (error) {
					//删除token  跳转到首页
					//await store.dispatch('user/resetToken')
					Message.error(error || '错误！')
					next(`/login?redirect=${to.path}`)
					NProgress.done()
				}
			}
		}
	} else {
		/* 没有token */
		if (whiteList.indexOf(to.path) !== -1) {
			//在白名单中，直接进入
			next()
		} else {
			//没在白名单，没有访问权限的页面重定向到登录页面/* 
			next(`/login?redirect=${to.path}`)
			NProgress.done()
		}
	}
})
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
