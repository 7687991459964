import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
Vue.use(Router)

// 静态路由,这里写好一些不需要从后台获取的路由,如首页,404页面
export const constantRoutes = [{
		name: 'sichanghangqing',
		path: '/shichanghangqing/list',
		component: () => import('@/views/shichanghangqing/index.vue'),
	},
	{
		name: 'yinongxinxishe',
		path: '/yinongxinxishe/list',
		component: () => import('@/views/yinongxinxishe/index.vue'),
	}
]

const createRouter = () => new Router({
	scrollBehavior: () => ({ y: 0 }),
	routes: constantRoutes
})

const router = createRouter()

// 重置路由
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

export default router