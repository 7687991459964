<template>
	<div class="wrap-video">
		<vueVideoPlayer 
		:src="moduleDataSource.src" 
		:cover_url="moduleDataSource.img" />
	</div>
</template>

<script>
	import vueVideoPlayer from '@/components/base/MyVideoPlayer'
	export default{
		name:'VideoPlayer',
		props:{
			moduleDataSource:{
				type:Object,
				default:()=>{}
			}
		},
		components:{
			vueVideoPlayer
		}
	}
</script>

<style scoped>
	.wrap-video{
	}
</style>
