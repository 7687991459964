<template>
	<div class="xycEcharts"></div>
</template>

<script>
	export default {
		name: 'xycEcharts',
		props: {
			data: Object
		},
		data() {
			return {}
		},
		created(){
		},
		methods: {
			setChart() {
				let option = {
					legend: {
						itemWidth: 8, // 标志图形的长度
						itemHeight: 8, // 标志图形的宽度
						// orient: 'vertical',
						// right: '15%',
						itemGap: 25,
						top: 'bottom',
						textStyle: {
							color: '#666',
							fontFamily: "PingFangSC-Regular",
							fontSize: 14,
						}
					},
					tooltip: {
						trigger: 'item',
						formatter: (params) => {
							// 设置默认显示第一个数据,函数接收一个参数，拿到所有配置项，遍历所有配置项，判断,下标为0的，第一个配置项信息，return 出去设置为默认值。
							return params.data.village_name
						},
						backgroundColor: '#11367a',
						textStyle: {
							color: '#6dd0e3',
							fontSize: 10,
						},
					},
					/* 	graphic: [{
								type: "text",
								left: "center",
								top: "34%",
								style: {
									text: this.data.count,
									textAlign: "center",
									fill: "rgba(0,0,0,0.85)",
									width: 30,
									height: 30,
									fontSize: 40,
									color: "rgba(0,0,0,0.85)",
									fontWeight: 'bold'

								}
							},
							{
								type: "text",
								left: "center",
								top: "48%",
								style: {
									text: "AAA类信用村",
									textAlign: "center",
									fill: "#666",
									width: 30,
									height: 30,
									fontSize: 16,
								}
							}
						], */
					series: [{
						type: 'pie',
						radius: ['45%', '60%'],
						center: ['50%', '43%'],
						avoidLabelOverlap: true,
						label: {
							fontSize: 16,
							lineHeight: 20,
							position: "center",
							formatter: (params) => {
								// 设置默认显示第一个数据,函数接收一个参数，拿到所有配置项，遍历所有配置项，判断,下标为0的，第一个配置项信息，return 出去设置为默认值。
								if (params.dataIndex === 0) {
									console.log(params.percent);
									return ("{Proportiondefault|" + params.value + "}" +
										"\n{name|" + params.name + "}");
								} else {
									return "";
								}
							},
							emphasis: {
								formatter: (params) => { //切换非默认选项配置数据展示
									return ("{Proportion|" + params.value + "}" +
										"\n{ProportionName|" + params.name + "}");
								},
							},
							rich: {
								Proportion: {
									//切换的数字参数配置
									fontSize: 38, //显示样式大小
									backgroundColor: "white", //覆盖数据的背景
									color: "#666",
									lineHeight: 40,
								},
								ProportionName: {
									//切换的数字参数配置
									fontSize: 23, //显示样式大小
									backgroundColor: "white", //覆盖数据的背景
									color: "#999",
									padding:4
								},
								name: {
									//切换的，name名字配置
									color: "#999",
									backgroundColor: "white",
								},
								name1: {
									//切换的，name名字配置
									color: "#999",
									backgroundColor: "white",
								},
								Proportiondefault: {
									fontSize: 20,
									backgroundColor: "white",
									zIndex: 10
								},
							},
						},
						labelLine: {
							show: false,
						},
						data: this.data.data
					}]
				};
				let myChart = this.$echarts(this.$el);
				myChart.clear();
				myChart.resize()
				myChart.setOption(option);

			}
		},
		mounted() {
			this.setChart()

		},
		watch: {
			data: {
				deep: true, //深度监听
				handler() {
					this.setChart();
				},
			},
		},
	}
</script>

<style scoped>
	.xycEcharts {
		width: 100%;
		height: 100%;

	}
</style>
