<template>
  <div class="html-content-wrap">
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "HtmlContent",
  props: {
    moduleDataSource: {
      type: Object,
      default: () => {},
    },
  },
  data(){
    return {
        content:''
    }
  },
  mounted(){
    this.content = this.moduleDataSource.content
  }
};
</script>

<style scoped>
.html-content-wrap {
  width: 1200px;
  margin: 0 auot;
}
</style>